export function decimal(v: string) {
  const test = /^\d*(\.\d{0,2})?$/.test(v);
  return test || "Número inválido. Formato esperado: 1,23";
}

export function required(v: any) {
  return (typeof v == "number" && v == 0) || !!v || "Campo obrigatório";
}

export function validateEmail(v: string) {
  const test = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v);
  return test || "Email inválido. Formato esperado: seu@email.com";
}

export function validatePassword(v: string) {
  if (!v) return true;
  const re = /^(?=.*\d)(?=.*[!@#$%^&*.])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return (
    re.test(v) ||
    "A Senha deve conter 8 ou mais caracteres, com pelo menos uma letra maiúscula, uma minúscula, um caractere especial (!@#$%^&*.) e um número. Ex: Abcd531@"
  );
}

export function minValue(min: number) {
  return (v: number) =>
    v == undefined || v >= min || `Deve ser maior ou igual a ${min}`;
}
