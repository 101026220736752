<template>
  <v-card title="Taxas de Aplicação">
    <template v-slot:prepend>
      <v-avatar>
        <v-icon color="primary" icon="mdi-spray"></v-icon>
      </v-avatar>
    </template>
    <v-card-text>
      <CRUDTable
        v-model:create-form-data="createFormData"
        v-model:edit-form-data="editFormData"
        v-model:form-error="formError"
        :url-base="urlBase"
        :messages="crudMsgs"
        :create-config="createConfig"
        :edit-config="editConfig"
        :headers="tableHeaders"
        :items-process="ratesProcess"
        @create-opened="getRelations"
        @edit-opened="getRelations"
      >
        <template v-slot:[`item.rate_min`]="{ item }">
          {{ SimpleMaskMoney.formatToCurrency(item.rate_min) }}
        </template>
        <template v-slot:[`item.rate_max`]="{ item }">
          {{ SimpleMaskMoney.formatToCurrency(item.rate_max) }}
        </template>

        <template v-slot:createForm>
          <v-autocomplete
            ref="productInput"
            label="Produto"
            name="product_id"
            :error-messages="productError"
            autocomplete="off"
            v-model="createFormData.product_id"
            :items="products"
            item-title="name"
            item-value="id"
            auto-select-first
            :loading="loadingRelations"
            :rules="[required]"
          ></v-autocomplete>
          <v-autocomplete
            label="Tipo de Equipamento"
            name="equipment_type_id"
            :error-messages="productError"
            autocomplete="off"
            v-model="createFormData.equipment_type_id"
            :items="equipmentTypes"
            item-title="name"
            item-value="id"
            auto-select-first
            :loading="loadingRelations"
            :rules="[required]"
          ></v-autocomplete>
          <div class="d-flex">
            <v-text-field
              label="Taxa Min."
              name="rate_min"
              :model-value="
                SimpleMaskMoney.formatToCurrency(createFormData.rate_min)
              "
              @update:model-value="
                (value) =>
                  (createFormData.rate_min =
                    SimpleMaskMoney.formatToNumber(value))
              "
              v-simple-mask-money
              validate-on="blur"
              :rules="[required]"
              @keydown.tab="rateMaxInput.focus()"
            ></v-text-field>
            <v-text-field
              ref="rateMaxInput"
              label="Taxa Máx."
              class="ml-2"
              :model-value="
                SimpleMaskMoney.formatToCurrency(createFormData.rate_max)
              "
              @update:model-value="
                (value) =>
                  (createFormData.rate_max =
                    SimpleMaskMoney.formatToNumber(value))
              "
              v-simple-mask-money
              name="rate_max"
              validate-on="blur"
              :rules="[required]"
              @keydown.tab="unitInput.focus()"
            ></v-text-field>
          </div>
          <v-text-field
            ref="unitInput"
            label="Unidade"
            placeholder="ml/min"
            name="unit"
            v-model="createFormData.unit"
            validate-on="blur"
            :rules="[required]"
          ></v-text-field>
        </template>

        <template v-slot:editForm>
          <v-autocomplete
            ref="productInput"
            label="Produto"
            name="product_id"
            :error-messages="productError"
            autocomplete="off"
            v-model="editFormData.product_id"
            :items="products"
            item-title="name"
            item-value="id"
            :loading="loadingRelations"
            :rules="[required]"
          ></v-autocomplete>
          <v-autocomplete
            label="Tipo de Equipamento"
            name="equipment_type_id"
            :error-messages="productError"
            autocomplete="off"
            v-model="editFormData.equipment_type_id"
            :items="equipmentTypes"
            item-title="name"
            item-value="id"
            :loading="loadingRelations"
            :rules="[required]"
          ></v-autocomplete>
          <div class="d-flex">
            <v-text-field
              label="Taxa Min."
              placeholder="0.00"
              name="rate_min"
              :model-value="
                SimpleMaskMoney.formatToCurrency(editFormData.rate_min)
              "
              @update:model-value="
                (value) =>
                  (editFormData.rate_min =
                    SimpleMaskMoney.formatToNumber(value))
              "
              v-simple-mask-money
              validate-on="blur"
              :rules="[required]"
            ></v-text-field>
            <v-text-field
              class="ml-2"
              label="Taxa Máx."
              placeholder="0.00"
              name="rate_max"
              :model-value="
                SimpleMaskMoney.formatToCurrency(editFormData.rate_max)
              "
              @update:model-value="
                (value) =>
                  (editFormData.rate_max =
                    SimpleMaskMoney.formatToNumber(value))
              "
              v-simple-mask-money
              validate-on="blur"
              :rules="[required]"
            ></v-text-field>
          </div>
          <v-text-field
            label="Unidade"
            placeholder="ml/min"
            name="unit"
            v-model="editFormData.unit"
            validate-on="blur"
            :rules="[required]"
          ></v-text-field>
        </template>
      </CRUDTable>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import CRUDTable from "@/components/CRUDTable.vue";
import { useSnackbar } from "@/store";
import { required } from "@/validators";
import { watchOnce } from "@vueuse/core";
import axios, { AxiosError } from "axios";
import SimpleMaskMoney from "simple-mask-money";
import { reactive, ref } from "vue";

const { showSnackbar } = useSnackbar();

const createFormDataDefault = {
  product_id: undefined,
  equipment_type_id: undefined,
  rate_min: 0.0,
  rate_max: 0.0,
  unit: "",
};
const editFormDataDefault = {
  product_id: undefined,
  equipment_type_id: undefined,
  rate_min: 0.0,
  rate_max: 0.0,
  unit: "",
};
const createFormData = reactive({ ...createFormDataDefault });
const editFormData = reactive({ ...editFormDataDefault });
const urlBase = "/admin/application-rates";
const crudMsgs = {
  unexpectedError:
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.",
  createSuccess: "Taxa de Aplicação criado com sucesso!",
  editSuccess: "Taxa de Aplicação editado com sucesso!",
};
const createConfig = {
  btnText: "Nova Taxa de Aplicação",
  btnIcon: "mdi-plus",
  formTitle: "Nova Taxa de Aplicação",
  formIcon: "mdi-spray",
  submitText: "Salvar",
  formDataDefault: createFormDataDefault,
  handleCreateError: checkExistingRateCreate,
};
const editConfig = {
  btnText: "Editar Taxa de Aplicação",
  formTitle: "Editando Taxa de Aplicação",
  formIcon: "mdi-engine",
  submitText: "Salvar",
  formDataDefault: editFormDataDefault,
  handleEditError: checkExistingRateEdit,
};
const tableHeaders = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Produto",
    key: "product.name",
  },
  {
    title: "Tipo de Equipamento",
    key: "equipment_type.name",
  },
  {
    title: "Taxa Min.",
    key: "rate_min",
  },
  {
    title: "Taxa Máx.",
    key: "rate_max",
  },
  {
    title: "Unidade",
    key: "unit",
  },
  {
    title: "Ações",
    key: "actions",
    sortable: false,
    align: "end",
  },
];

const unitInput = ref();
const rateMaxInput = ref();
const existingNameMsg = (product: string, equipment_type: string) =>
  `Taxa de Aplicação para ${product} - ${equipment_type} já existe`;
const formError = ref("");
const productError = ref("");
const productInput = ref();

function checkExistingRateCreate(err: AxiosError<any, any>) {
  if (err.response?.status === 409) {
    formError.value = existingNameMsg(
      products.value.find((p) => p.id === createFormData.product_id)?.name,
      equipmentTypes.value.find(
        (p) => p.id === createFormData.equipment_type_id
      )?.name
    );
    productInput.value.focus();
    watchOnce(
      () => createFormData.product_id,
      () => (productError.value = "")
    );
    return true;
  }
  return false;
}

function checkExistingRateEdit(err: AxiosError<any, any>) {
  if (err.response?.status === 409) {
    formError.value = existingNameMsg(
      products.value.find((p) => p.id === editFormData.product_id)?.name,
      equipmentTypes.value.find((p) => p.id === editFormData.equipment_type_id)
        ?.name
    );
    productInput.value.focus();
    watchOnce(
      () => editFormData,
      () => (productError.value = "")
    );
    return true;
  }
  return false;
}

function ratesProcess(rate: any[]) {
  return rate.map((e) => {
    e.product_id = e.product.id;
    e.equipment_type_id = e.equipment_type.id;
    return e;
  });
}

const loadingRelations = ref(false);
const products = ref<any[]>([]);
const equipmentTypes = ref<any[]>([]);
async function getRelations() {
  try {
    loadingRelations.value = true;
    const res = await Promise.all([
      axios.get("/admin/products"),
      axios.get("/admin/equipment-types"),
    ]);
    products.value = res[0].data;
    equipmentTypes.value = res[1].data;
  } catch (err) {
    showSnackbar(crudMsgs.unexpectedError, "error");
  } finally {
    loadingRelations.value = false;
  }
}
</script>
