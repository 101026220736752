<template>
  <v-container fluid class="bg-white">
    <v-row>
      <v-col cols="9">
        <CRUDTable
          v-model:create-form-data="createFormData"
          v-model:edit-form-data="editFormData"
          v-model:form-error="formError"
          :url-base="urlBase"
          :messages="crudMsgs"
          :create-config="createConfig"
          :edit-config="editConfig"
          :headers="tableHeaders"
          :items-process="usersProcess"
          @create-opened="loadAll"
          @edit-opened="loadAll"
        >
          <template v-slot:[`item.is_active`]="{ item }">
            <v-icon
              :icon="item.is_active ? 'mdi-check' : 'mdi-cancel'"
              :color="item.is_active ? 'success' : 'error'"
            ></v-icon>
          </template>
          <template v-slot:[`item.is_admin`]="{ item }">
            <v-icon
              :icon="item.is_admin ? 'mdi-check' : 'mdi-cancel'"
              :color="item.is_admin ? 'success' : 'error'"
            ></v-icon>
          </template>
          <template v-slot:createForm>
            <v-autocomplete
              clearable
              ref="stationInput"
              label="Estação"
              auto-select-first
              name="station"
              v-model="createFormData.identification"
              autocomplete="off"
              :items="stations"
              :loading="loadingStations"
              item-title="name"
              item-value="identification"
              validate-on="blur"
            ></v-autocomplete>
            <v-autocomplete
              clearable
              label="Organização"
              auto-select-first
              name="organization"
              v-model="createFormData.organization_id"
              autocomplete="off"
              :items="organizations"
              :loading="loadingOrganizations"
              item-title="name"
              item-value="id"
              validate-on="blur"
            ></v-autocomplete>
          </template>

          <template v-slot:editForm>
            <v-autocomplete
              clearable
              ref="stationInput"
              label="Estação"
              auto-select-first
              name="station"
              v-model="editFormData.identification"
              autocomplete="off"
              :items="stations"
              :loading="loadingStations"
              item-title="name"
              item-value="identification"
              validate-on="blur"
            ></v-autocomplete>
            <v-autocomplete
              clearable
              label="Organização"
              auto-select-first
              name="organization"
              v-model="editFormData.organization_id"
              autocomplete="off"
              :items="organizations"
              :loading="loadingOrganizations"
              item-title="name"
              item-value="id"
              validate-on="blur"
            ></v-autocomplete>
          </template>
        </CRUDTable>
      </v-col>
      <v-col>
        <span class="text-h5">Condições Ideais de Aplicação</span>
        <v-form
          v-model="idealConditionsForm"
          @submit.prevent="submitIdealConditions"
        >
          <v-text-field
            label="Umidade"
            name="humidity"
            v-simple-mask-money
            :model-value="
              SimpleMaskMoney.formatToCurrency(idealConditions.humidity)
            "
            @update:model-value="
              (value) =>
                (idealConditions.humidity =
                  SimpleMaskMoney.formatToNumber(value))
            "
            prefix="acima de"
            suffix="%"
            :rules="[required]"
            @keydown.tab="temperatureInput.focus()"
            min-width="200"
          ></v-text-field>
          <v-text-field
            ref="temperatureInput"
            label="Temperatura (abaixo de)"
            name="temperature"
            v-simple-mask-money
            :model-value="
              SimpleMaskMoney.formatToCurrency(idealConditions.temperature)
            "
            @update:model-value="
              (value) =>
                (idealConditions.temperature =
                  SimpleMaskMoney.formatToNumber(value))
            "
            prefix="abaixo de"
            suffix="°C"
            @keydown.tab="windSpeedInput.focus()"
            min-width="200"
          ></v-text-field>
          <v-text-field
            ref="windSpeedInput"
            label="Vel. Vento"
            name="wind_speed"
            v-simple-mask-money
            :model-value="
              SimpleMaskMoney.formatToCurrency(idealConditions.wind_speed)
            "
            @update:model-value="
              (value) =>
                (idealConditions.wind_speed =
                  SimpleMaskMoney.formatToNumber(value))
            "
            prefix="abaixo de"
            suffix="km/h"
            :rules="[required]"
            min-width="200"
          ></v-text-field>
          <v-btn
            text="Salvar"
            variant="tonal"
            color="primary"
            block
            prepend-icon="mdi-content-save"
            type="submit"
            :loading="loadingSubmit"
            :rules="[required]"
          ></v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import CRUDTable, {
  CreateConfig,
  EditConfig,
  Messages,
} from "@/components/CRUDTable.vue";
import { localeDateTimeOptions } from "@/consts";
import { useSnackbar } from "@/store";
import { watchOnce } from "@vueuse/core";
import axios, { AxiosError } from "axios";
import { onBeforeMount, reactive, ref } from "vue";
import { useLoadOrganizations, useLoadStations } from "./composables";
import SimpleMaskMoney from "simple-mask-money";
import { required } from "@/validators";

const { showSnackbar } = useSnackbar();

onBeforeMount(() => {
  loadIdealConditions();
});

const idealConditionsSuccess = "Condições Ideais atualizadas com sucesso!";

const defaultCreateFormData = {
  identification: undefined,
  organization_id: undefined,
};
const defaultEditFormData = {
  identification: undefined,
  organization_id: undefined,
};
const createFormData = reactive({ ...defaultCreateFormData });
const editFormData = reactive({ ...defaultEditFormData });

const urlBase = "/admin/stations";
const crudMsgs: Messages = {
  unexpectedError:
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.",
  createSuccess: "Estação criada com sucesso!",
  editSuccess: "Estação editada com sucesso!",
};
const createConfig: CreateConfig = {
  btnText: "Nova Estação",
  btnIcon: "mdi-plus",
  formTitle: "Nova Estação",
  formIcon: "mdi-thermometer-plus",
  submitText: "Salvar",
  formDataDefault: defaultCreateFormData,
  handleCreateError: checkDuplicate,
};
const editConfig: EditConfig = {
  btnText: "Editar Estação",
  formTitle: "Editando Estação",
  formIcon: "mdi-thermometer",
  submitText: "Salvar",
  formDataDefault: defaultEditFormData,
  handleEditError: checkDuplicate,
};

const tableHeaders = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Nome",
    key: "name",
  },
  {
    title: "Identificação",
    key: "identification",
  },
  {
    title: "Organização",
    key: "organization.name",
  },
  {
    title: "Ações",
    key: "actions",
    sortable: false,
    align: "end",
  },
];

const stationInput = ref();
const formError = ref("");
const duplicateMsg = (organization: string, identification: string) =>
  `Estação ${identification} para ${organization} já existe`;

function checkDuplicate(err: AxiosError<any, any>) {
  if (err.response?.status === 409) {
    formError.value = duplicateMsg(
      organizations.value.find(
        (o) =>
          o.id === createFormData.organization_id ||
          o.id === editFormData.organization_id
      )?.name,
      stations.value.find(
        (o) =>
          o.id === createFormData.identification ||
          o.id === editFormData.identification
      )?.name
    );
    stationInput.value.focus();
    watchOnce(
      [() => createFormData, () => editFormData],
      () => (formError.value = "")
    );
    return true;
  }
  return false;
}

const {
  organizations,
  loadingOrganizations,
  load: loadOrgs,
} = useLoadOrganizations();

const { stations, loadingStations, load: loadStations } = useLoadStations();

const loadAll = async () => {
  await Promise.all([loadOrgs(), loadStations()]);
};

function usersProcess(users: any[]) {
  return users.map((user: any) => {
    user.created_at = new Date(user.created_at).toLocaleString(
      undefined,
      localeDateTimeOptions
    );
    user.organization_id = user.organization?.id;
    return user;
  });
}

const idealConditions = reactive({
  humidity: 0,
  temperature: 0,
  wind_speed: 0,
});
const loadingIdealConditions = ref(false);
const idealConditionsForm = ref();
const loadingSubmit = ref(false);
const temperatureInput = ref();
const windSpeedInput = ref();

async function loadIdealConditions() {
  loadingIdealConditions.value = true;
  try {
    const res = await axios.get("/admin/ideal-application-conditions");
    Object.assign(idealConditions, res.data);
  } catch (err) {
    if (err instanceof AxiosError && err.response?.status === 500) {
      showSnackbar(crudMsgs.unexpectedError, "error");
    }
  } finally {
    loadingIdealConditions.value = false;
  }
}

async function submitIdealConditions() {
  loadingSubmit.value = true;
  try {
    await axios.put("/admin/ideal-application-conditions", idealConditions);
    showSnackbar(idealConditionsSuccess, "success");
  } catch (err) {
    showSnackbar(crudMsgs.unexpectedError, "error");
  } finally {
    loadingSubmit.value = false;
  }
}
</script>
