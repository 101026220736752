<template>
  <FormDialog
    title="Editar Atividade"
    titleIcon="mdi-calendar-edit"
    submitText="Salvar"
    :open="open"
    :form-error="formError"
    :submit="submitEdit"
    @close="closeEdit"
  >
    <v-text-field
      type="date"
      label="Data de Início"
      name="start_date"
      v-model="editFormData.start_date"
      validate-on="input"
      :rules="[required]"
      autofocus
      disabled
    ></v-text-field>
    <div class="d-flex">
      <v-autocomplete
        class="w-50"
        label="Produto"
        auto-select-first
        name="produto"
        v-model="editFormData.product_id"
        autocomplete="off"
        :items="products"
        :loading="loadingProducts"
        item-title="name"
        item-value="id"
        validate-on="input"
        :rules="[required]"
        disabled
      ></v-autocomplete>
      <div class="ml-2"></div>
      <v-autocomplete
        class="w-50"
        label="Equipamento"
        auto-select-first
        name="equipment"
        v-model="editFormData.equipment_id"
        autocomplete="off"
        :items="equipments"
        :loading="loadingEquipments"
        item-title="name"
        item-value="id"
        validate-on="input"
        :rules="[required]"
        disabled
      ></v-autocomplete>
    </div>
    <div class="d-flex">
      <v-text-field
        class="w-50"
        type="number"
        label="Nº de Ciclos"
        name="total_cycles"
        v-model="editFormData.total_cycles"
        validate-on="input"
        :rules="[required, minCycles]"
      ></v-text-field>
      <div class="ml-2"></div>
      <v-text-field
        class="w-50"
        type="number"
        label="Intervalo (dias)"
        name="cycle_interval_days"
        v-model="editFormData.cycle_interval_days"
        validate-on="input"
        :rules="[required, minValue(1)]"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-text-field
        class="w-50"
        :hint="applicationRateHint"
        label="Taxa de Aplicação"
        name="application_rate"
        :model-value="
          SimpleMaskMoney.formatToCurrency(editFormData.application_rate)
        "
        @update:model-value="
          (value) =>
            (editFormData.application_rate =
              SimpleMaskMoney.formatToNumber(value))
        "
        v-simple-mask-money
        validate-on="input"
        :loading="loadingApplicationRate"
        :rules="[required]"
        @keydown.tab="unitInput.focus()"
        disabled
      ></v-text-field>
      <div class="ml-2"></div>
      <v-text-field
        ref="unitInput"
        class="w-50"
        label="Unidade"
        placeholder="ml/min"
        name="application_unit"
        v-model="editFormData.application_unit"
        validate-on="input"
        :loading="loadingApplicationUnit"
        :rules="[required]"
        disabled
      ></v-text-field>
    </div>
  </FormDialog>
</template>

<script setup lang="ts">
import FormDialog from "@/components/FormDialog.vue";
import { useSnackbar } from "@/store";
import { minValue, required } from "@/validators";
import { watchOnce } from "@vueuse/core";
import axios from "axios";
import SimpleMaskMoney from "simple-mask-money";
import { reactive, ref, watch } from "vue";

const props = defineProps<{
  open: boolean;
  activity: any;
}>();

const emit = defineEmits<{
  (e: "edited"): void;
  (e: "close"): void;
}>();

const { showSnackbar } = useSnackbar();

const messages = {
  editSuccess: "Atividade editada com sucesso",
  unexpectedError: "Ocorreu um erro inesperado. Tente novamente mais tarde.",
  productsError: "Ocorreu um erro ao carregar os produtos.",
  equipmentError: "Ocorreu um erro ao carregar os equipamentos.",
};

const formDataDefaults = {
  start_date: undefined,
  total_cycles: undefined,
  cycle_interval_days: undefined,
  product_id: undefined,
  equipment_id: undefined,
  application_rate: "",
  application_unit: undefined,
};
const editFormData = reactive({ ...formDataDefaults });
const editApplication = ref();
const formError = ref("");
const unitInput = ref();
let unwatchEditProduct: () => void;
let unwatchEditProductEquipment: () => void;

watch(
  () => props.open,
  () => {
    if (props.open) {
      initEdit();
    }
  }
);

function initEdit() {
  editApplication.value = props.activity.application;
  Object.assign(editFormData, props.activity.application);
  editFormData.start_date = props.activity.start_date;
  editFormData.product_id = props.activity.application.product.id;
  editFormData.equipment_id = props.activity.application.equipment.id;
  Promise.all([listProducts(), listEquipments()]).then(() => {
    getApplicationRate(editFormData);
  });

  unwatchEditProduct = watch(
    () => editFormData.product_id,
    () => {
      editFormData.total_cycles = products.value.find(
        (p) => p.id === editFormData.product_id
      )?.number_of_cycles;
      editFormData.cycle_interval_days = products.value.find(
        (p) => p.id === editFormData.product_id
      )?.cycle_interval_days;
    }
  );

  unwatchEditProductEquipment = watch(
    [() => editFormData.product_id, () => editFormData.equipment_id],
    async () => {
      if (editFormData.product_id && editFormData.equipment_id) {
        getApplicationRate(editFormData);
      }
    }
  );
}

async function submitEdit() {
  try {
    await axios.patch(
      `/application-activities/${editApplication.value.id}`,
      editFormData
    );
    closeEdit();
    showSnackbar(messages.editSuccess, "success");
    emit("edited");
  } catch (err) {
    handleSubmitError(err);
  }
}

function closeEdit() {
  unwatchEditProduct();
  unwatchEditProductEquipment();
  Object.assign(editFormData, formDataDefaults);
  editApplication.value = undefined;
  formError.value = "";
  emit("close");
}

const products = ref<any[]>([]);
const loadingProducts = ref(false);

async function listProducts() {
  loadingProducts.value = true;
  try {
    const res = await axios.get("/application-products");
    products.value = res.data;
  } catch (err) {
    formError.value = messages.productsError;
  } finally {
    loadingProducts.value = false;
  }
}

const equipments = ref<any[]>([]);
const loadingEquipments = ref(false);

async function listEquipments() {
  loadingEquipments.value = true;
  try {
    const res = await axios.get("/application-equipments");
    equipments.value = res.data;
  } catch (err) {
    formError.value = messages.equipmentError;
  } finally {
    loadingEquipments.value = false;
  }
}

const loadingApplicationRate = ref(false);
const loadingApplicationUnit = ref(false);
const applicationRateHint = ref("");

async function getApplicationRate(formData: any, updateInputs = false) {
  loadingApplicationRate.value = true;
  loadingApplicationUnit.value = true;
  try {
    const res = await axios.get("/application-rate", {
      params: {
        product_id: formData.product_id,
        equipment_id: equipments.value.find(
          (e) => e.id === formData.equipment_id
        ).type.id,
      },
    });
    if (updateInputs) {
      formData.application_rate = res.data.rate_min;
      formData.application_unit = res.data.unit;
    }
    applicationRateHint.value = `Recomendação: ${
      res.data.rate_min != res.data.rate_max
        ? res.data.rate_min + " a " + res.data.rate_max
        : res.data.rate_min
    }`;
  } finally {
    loadingApplicationRate.value = false;
    loadingApplicationUnit.value = false;
  }
}

function handleSubmitError(err: any) {
  if (axios.isAxiosError(err)) {
    console.log(err.response?.data);
  }
  formError.value = messages.unexpectedError;
  watchOnce(
    () => editFormData,
    () => (formError.value = "")
  );
}

function minCycles(v: number) {
  const exs = editApplication.value.executions.length;
  return (
    v >= exs || `Deve ser maior ou igual a quantidade de execuções (${exs})`
  );
}
</script>
