import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { lineString } from "@turf/turf";
import dayjs from "dayjs";
import mapboxgl, { MapLayerEventType } from "mapbox-gl";
import { computed, ref } from "vue";
import { booleanWithin } from "@turf/turf";

const ORGS_SOURCE = "orgs";
const ORGS_LINE_LAYER = "orgs-line";
const SELECTED_SOURCE = "selected-field";
const SELECTED_OUTLINE_LAYER = "selected-field-outline";
const FIELDS_SOURCE = "fields";
const FIELDS_POLYGON_LAYER = "fields-polygon";
const FIELDS_OUTLINE_LAYER = "fields-outline";
const POINTS_SOURCE = "points";
const POINTS_CIRCLE_LAYER = "points-circle";
const POINTS_TEXT_LAYER = "points-text";
const LINE_SOURCE = "lines";
const LINE_LAYER = "lines-layer";
const STATIONS_SOURCE = "stations";
const STATIONS_ICON_LAYER = "stations-icon";
const STATIONS_CIRCLE_LAYER = "stations-circle";
const MAP_STYLES: { [key: string]: string } = {
  satellite: "mapbox://styles/farmgo/clza9ozyp00hc01qmegv6g0bt",
  streets: "mapbox://styles/farmgo/clvmqu60e043901pe8gbz7h4a",
  hibrid: "mapbox://styles/farmgo/clza9dfhg00i701qn6mj1enyq",
};

const editingField = ref(false);

let map: mapboxgl.Map;
let mapCenter: mapboxgl.LngLatLike;
let draw: MapboxDraw;
let orgsSource: mapboxgl.GeoJSONSource;
let pointsSource: mapboxgl.GeoJSONSource;
let linesSource: mapboxgl.GeoJSONSource;
let fieldsSource: mapboxgl.GeoJSONSource;
let selectedFieldSource: mapboxgl.GeoJSONSource;
let stationsSource: mapboxgl.GeoJSONSource;

function initMap(style = "satellite", onload?: () => void) {
  mapboxgl.accessToken =
    "pk.eyJ1IjoiZmFybWdvIiwiYSI6ImNsdHlob2l3MzBndzYyam56dWY2dWVlcWcifQ.xRFKRjBRrj-7lLIwEQ7RCQ";

  map = new mapboxgl.Map({
    container: "map",
    style: MAP_STYLES[style],
    center: [-51.111662, -24.165419],
    zoom: 12,
    attributionControl: false,
  });
  draw = new MapboxDraw({
    defaultMode: "simple_select",
    displayControlsDefault: false,
  });

  map.addControl(new mapboxgl.NavigationControl());
  map.addControl(draw);

  map.on("style.load", () => {
    initOrgsSource();
    initFieldsSource();
    initPointsSource();
    initStationsSource();
    if (onload) onload();
  });
}

function changeStyle(style: string) {
  mapCenter = map.getCenter();
  map.setStyle(MAP_STYLES[style]);
}

function checkWithinOrg(feature: any) {
  for (const o of map.querySourceFeatures(ORGS_SOURCE)) {
    const within = booleanWithin(feature, o);
    if (within) {
      return o.properties?.id;
    }
  }
  return undefined;
}

function initOrgsSource() {
  map.addSource(ORGS_SOURCE, {
    type: "geojson",
  });
  orgsSource = map.getSource(ORGS_SOURCE) as mapboxgl.GeoJSONSource;

  map.addLayer({
    id: ORGS_LINE_LAYER,
    type: "line",
    source: ORGS_SOURCE,
    layout: {},
    paint: {
      "line-color": "#000000",
      "line-width": 2,
      "line-opacity": 1,
      "line-dasharray": [2, 2],
    },
  });
}

function initFieldsSource() {
  map.addSource(FIELDS_SOURCE, {
    type: "geojson",
  });
  fieldsSource = map.getSource(FIELDS_SOURCE) as mapboxgl.GeoJSONSource;

  map.addLayer({
    id: FIELDS_POLYGON_LAYER,
    type: "fill",
    source: FIELDS_SOURCE,
    paint: {
      "fill-color": [
        "match",
        ["get", "color"],
        "red",
        "#E53935",
        "green",
        "#43A047",
        "blue",
        "#0080ff",
        /* other */ "#0080ff",
      ],
      "fill-opacity": 0.7,
    },
  });
  map.addLayer({
    id: FIELDS_OUTLINE_LAYER,
    type: "line",
    source: FIELDS_SOURCE,
    paint: {
      "line-color": [
        "match",
        ["get", "color"],
        "red",
        "#E53935",
        "green",
        "#43A047",
        "blue",
        "#0080ff",
        /* other */ "#0080ff",
      ],
      "line-opacity": 1,
      "line-width": 2,
    },
  });

  map.addSource(SELECTED_SOURCE, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });
  selectedFieldSource = map.getSource(
    SELECTED_SOURCE
  ) as mapboxgl.GeoJSONSource;
  map.addLayer({
    id: SELECTED_OUTLINE_LAYER,
    type: "line",
    source: SELECTED_SOURCE,
    paint: {
      "line-color": "#3F51B5",
      "line-opacity": 1,
      "line-width": 3,
    },
  });

  map.on("mouseenter", FIELDS_POLYGON_LAYER, (e) => {
    map.getCanvas().style.cursor = "pointer";
  });

  map.on("mouseleave", FIELDS_POLYGON_LAYER, (e) => {
    map.getCanvas().style.cursor = "";
  });
}

function initPointsSource() {
  map.addSource(POINTS_SOURCE, {
    type: "geojson",
  });
  pointsSource = map.getSource(POINTS_SOURCE) as mapboxgl.GeoJSONSource;
  map.addSource(LINE_SOURCE, {
    type: "geojson",
  });
  linesSource = map.getSource(LINE_SOURCE) as mapboxgl.GeoJSONSource;

  map.addLayer({
    id: POINTS_CIRCLE_LAYER,
    type: "circle",
    source: POINTS_SOURCE,
    paint: {
      "circle-color": "#FF9800",
      "circle-radius": 8,
    },
  });

  map.addLayer({
    id: LINE_LAYER,
    type: "line",
    source: LINE_SOURCE,
    paint: {
      "line-color": "#FF9800",
    },
  });

  map.addLayer({
    id: POINTS_TEXT_LAYER,
    type: "symbol",
    source: POINTS_SOURCE,
    layout: {
      "text-field": ["get", "order"],
      "text-size": 12,
      "text-offset": [0, 1.5],
    },
  });

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  map.on("mouseenter", POINTS_CIRCLE_LAYER, (e) => {
    map.getCanvas().style.cursor = "pointer";
    if (e.features && e.features[0].geometry.type === "Point") {
      const coordinates = e.features[0].geometry.coordinates.slice() as [
        number,
        number
      ];
      const props = e.features[0].properties;
      popup
        .setLngLat(coordinates)
        .setHTML(
          `
          <p>
            <i class='mdi-timer-outline mdi v-icon notranslate v-theme--farmgoTheme v-icon--size-default text-medium-emphasis mr-1' aria-hidden='true' data-tippy-content='Duração 1h 4m' style='vertical-align: sub;'></i>
            <span>${dayjs(props?.logged_at).format("HH:mm")}</span>
          </p>
          <p>
            <i class='mdi-speedometer mdi v-icon notranslate v-theme--farmgoTheme v-icon--size-default text-medium-emphasis mr-1' aria-hidden='true' data-tippy-content='Duração 1h 4m' style='vertical-align: sub;'></i>
            <span>${props?.speed.toFixed(1).replace(".", ",")} km/h</span>
          </p>
          `
        )
        .addTo(map);
    }
  });

  map.on("mouseleave", POINTS_CIRCLE_LAYER, (e) => {
    map.getCanvas().style.cursor = "";
    popup.remove();
  });
}

function initStationsSource() {
  map.addSource(STATIONS_SOURCE, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });
  stationsSource = map.getSource(STATIONS_SOURCE) as mapboxgl.GeoJSONSource;

  map.addLayer({
    id: STATIONS_ICON_LAYER,
    type: "symbol",
    source: STATIONS_SOURCE,
    layout: {
      "icon-image": "thermometer-circle",
      "icon-size": 1,
      "icon-allow-overlap": true,
    },
  });

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  map.on("mouseenter", STATIONS_ICON_LAYER, (e) => {
    map.getCanvas().style.cursor = "pointer";
    if (e.features && e.features[0].geometry.type === "Point") {
      const coordinates = e.features[0].geometry.coordinates.slice() as [
        number,
        number
      ];
      const props = e.features[0].properties;
      popup
        .setLngLat(coordinates)
        .setHTML(
          `
          <p>
            <span>${props?.name}</span>
          </p>
          `
        )
        .addTo(map);
    }
  });

  map.on("mouseleave", STATIONS_ICON_LAYER, (e) => {
    map.getCanvas().style.cursor = "";
    popup.remove();
  });
}

type GeoJSONData =
  | string
  | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
  | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;

export function useFieldsMap() {
  function replaceOrgs(orgs: GeoJSONData) {
    orgsSource.setData(orgs);
  }

  function replaceFields(fields: GeoJSONData) {
    fieldsSource.setData(fields);
  }

  function replaceSelectedField(field: GeoJSONData) {
    selectedFieldSource.setData(field);
  }

  function replaceStations(stations: GeoJSONData) {
    stationsSource.setData(stations);
  }

  function replacePoints(points: any, route?: any) {
    if (route) {
      linesSource.setData(route);
    } else {
      try {
        const coords = [];
        for (const p of points.features) {
          coords.push([p.geometry.coordinates[0], p.geometry.coordinates[1]]);
        }
        linesSource.setData(lineString(coords));
      } catch (e) {
        linesSource.setData({ type: "FeatureCollection", features: [] });
      }
    }

    try {
      pointsSource.setData(points);
    } catch (e) {
      pointsSource.setData({ type: "FeatureCollection", features: [] });
    }
  }

  function showFields() {
    map.setLayoutProperty(FIELDS_POLYGON_LAYER, "visibility", "visible");
    map.setLayoutProperty(FIELDS_OUTLINE_LAYER, "visibility", "visible");
  }

  function hideFields() {
    map.setLayoutProperty(FIELDS_POLYGON_LAYER, "visibility", "none");
    map.setLayoutProperty(FIELDS_OUTLINE_LAYER, "visibility", "none");
  }

  function showSelectedField() {
    map.setLayoutProperty(SELECTED_OUTLINE_LAYER, "visibility", "visible");
  }

  function hideSelectedField() {
    map.setLayoutProperty(SELECTED_OUTLINE_LAYER, "visibility", "none");
  }

  function showPoints() {
    map.setLayoutProperty(POINTS_CIRCLE_LAYER, "visibility", "visible");
    map.setLayoutProperty(POINTS_TEXT_LAYER, "visibility", "visible");
    map.setLayoutProperty(LINE_LAYER, "visibility", "visible");
  }

  function hidePoints() {
    map.setLayoutProperty(POINTS_CIRCLE_LAYER, "visibility", "none");
    map.setLayoutProperty(POINTS_TEXT_LAYER, "visibility", "none");
    map.setLayoutProperty(LINE_LAYER, "visibility", "none");
  }

  function onFieldClick(fn: any) {
    map.on("click", FIELDS_POLYGON_LAYER, fn);
  }

  function bindMapEvent(event: string, fn: (e: any) => void, layer?: string) {
    if (layer) map.on(event as keyof MapLayerEventType, layer, fn);
    else map.on(event, fn);
  }

  function unbindMapEvent(event: string, fn: (e: any) => void, layer?: string) {
    if (layer) map.off(event as keyof MapLayerEventType, layer, fn);
    else map.off(event, fn);
  }

  function centerMap(lat: number, lng: number) {
    if (mapCenter) map.setCenter(mapCenter);
    else map.setCenter([lng, lat]);
  }

  return {
    SELECTED_SOURCE,
    SELECTED_OUTLINE_LAYER,
    FIELDS_SOURCE,
    FIELDS_POLYGON_LAYER,
    FIELDS_OUTLINE_LAYER,
    POINTS_SOURCE,
    POINTS_CIRCLE_LAYER,
    POINTS_TEXT_LAYER,
    LINE_SOURCE,
    LINE_LAYER,
    STATIONS_SOURCE,
    map: computed(() => map),
    draw: computed(() => draw),
    editingField,
    initMap,
    changeStyle,
    checkWithinOrg,
    replaceOrgs,
    replaceFields,
    replaceSelectedField,
    replacePoints,
    replaceStations,
    showFields,
    hideFields,
    showSelectedField,
    hideSelectedField,
    showPoints,
    hidePoints,
    onFieldClick,
    bindMapEvent,
    unbindMapEvent,
    initStationsSource,
    centerMap,
  };
}
