<template>
  <div class="d-flex w-100" style="height: 100%">
    <div id="map" class="w-100" style="height: 100%"></div>
    <div
      style="top: 4.5rem; position: absolute; z-index: 2; pointer-events: none"
    >
      <div class="d-flex">
        <div
          class="d-flex flex-column ml-2 rounded"
          style="pointer-events: all"
        >
          <v-btn-toggle
            class="flex-column bg-white"
            v-model="toggleVisualization"
            style="height: auto"
            variant="text"
            color="primary"
            :mandatory="true"
            :elevation="2"
          >
            <span class="text-h6 text-center mx-2 my-1">Visualizações</span>
            <v-divider></v-divider>
            <v-btn height="36">Atividades</v-btn>
            <v-btn height="36">Vistorias</v-btn>
          </v-btn-toggle>
          <v-btn-toggle
            v-show="toggleVisualization == M.Visualization.placevisits"
            class="flex-column bg-white mt-1"
            v-model="toggleSubVisualization"
            style="height: auto"
            variant="text"
            color="secondary"
            :mandatory="true"
            :elevation="2"
          >
            <v-btn height="36">Todas</v-btn>
          </v-btn-toggle>
        </div>
        <v-btn
          class="mx-2"
          icon="mdi-map"
          density="comfortable"
          style="pointer-events: all"
        >
          <v-icon icon="mdi-map"></v-icon>
          <v-menu
            activator="parent"
            :close-on-content-click="false"
            offset="4"
            location="end"
          >
            <v-sheet class="pa-2">
              <v-radio-group
                v-model="mapStyle"
                @change="changeMapStyle"
                hide-details
                density="default"
              >
                <v-radio label="Satélite" value="satellite"></v-radio>
                <v-radio label="Ruas" value="streets"></v-radio>
                <v-radio label="Híbrido" value="hibrid"></v-radio>
              </v-radio-group>
            </v-sheet>
          </v-menu>
        </v-btn>
      </div>
    </div>
    <div
      style="bottom: 2.5rem; position: fixed; z-index: 2; pointer-events: none"
    >
      <div class="d-flex mx-1">
        <v-btn
          class="mx-1"
          icon="mdi-information-outline"
          density="comfortable"
          style="pointer-events: all"
        >
          <v-icon icon="mdi-information-outline"></v-icon>
          <v-menu
            activator="parent"
            :close-on-content-click="false"
            offset="4"
            location="end"
            :persistent="true"
            :no-click-animation="true"
          >
            <v-list v-if="toggleVisualization == M.Visualization.activities">
              <v-list-item title="Atividade para hoje">
                <template v-slot:prepend>
                  <v-icon
                    icon="mdi-square-rounded"
                    color="success"
                    style="opacity: 1"
                  ></v-icon>
                </template>
              </v-list-item>
              <v-list-item title="Atividade atrasada">
                <template v-slot:prepend>
                  <v-icon
                    icon="mdi-square-rounded"
                    color="error"
                    style="opacity: 1"
                  ></v-icon>
                </template>
              </v-list-item>
            </v-list>
            <v-list
              v-if="
                toggleVisualization == M.Visualization.placevisits &&
                toggleSubVisualization == M.VisualizationPlacevisits.all
              "
            >
              <v-list-item title="Sem foco">
                <template v-slot:prepend>
                  <v-icon
                    icon="mdi-square-rounded"
                    color="success"
                    style="opacity: 1"
                  ></v-icon>
                </template>
              </v-list-item>
              <v-list-item title="Com foco">
                <template v-slot:prepend>
                  <v-icon
                    icon="mdi-square-rounded"
                    color="error"
                    style="opacity: 1"
                  ></v-icon>
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
      </div>
    </div>
    <div
      class="w-100 d-flex"
      style="
        bottom: 0.5rem;
        position: absolute;
        z-index: 2;
        pointer-events: none;
      "
    >
      <v-spacer></v-spacer>
      <v-toolbar
        v-if="userStore.canCreate || selectedField || editingField"
        density="compact"
        class="bg-white elevation-3 pa-1 rounded"
        style="width: fit-content; pointer-events: all"
      >
        <div
          id="default-toolbar"
          v-show="!creatingField && !editingField && !selectedField"
        >
          <v-btn
            v-if="userStore.canCreate"
            flat
            data-tippy-content="Desenhar Área"
            class="rounded-0"
            icon="mdi-shape-polygon-plus"
            @click="drawField"
            :disabled="!userStore.canCreate"
          >
          </v-btn>
        </div>
        <div id="creating-toolbar" v-show="creatingField">
          <v-btn
            flat
            data-tippy-content="Cancelar"
            class="rounded-0"
            icon="mdi-close"
            @click="cancelDrawingField"
          ></v-btn>
          <v-btn
            flat
            :disabled="!canFinishField"
            data-tippy-content="Concluir Área"
            class="rounded-0"
            icon="mdi-check"
            @click="finishField"
          ></v-btn>
        </div>
        <div id="selected-toolbar" v-show="selectedField && !editingField">
          <v-btn
            flat
            data-tippy-content="Cancelar"
            class="rounded-0"
            icon="mdi-close"
            @click="deselectField"
          ></v-btn>
          <v-btn
            v-if="userStore.canCreate"
            flat
            data-tippy-content="Editar desenho da Área"
            class="rounded-0"
            icon="mdi-vector-polyline-edit"
            @click="editField"
          ></v-btn>
          <v-btn
            v-if="userStore.canCreate"
            flat
            data-tippy-content="Editar dados Área"
            class="rounded-0"
            icon="mdi-pencil"
            @click="editForm"
          ></v-btn>
        </div>
        <div id="editing-toolbar" v-show="editingField">
          <v-btn
            flat
            data-tippy-content="Cancelar"
            class="rounded-0"
            icon="mdi-close"
            @click="cancelEditingField"
          ></v-btn>
          <v-btn
            flat
            :disabled="!canFinishField"
            data-tippy-content="Salvar Edição"
            class="rounded-0"
            icon="mdi-check"
            @click="finishEditingField"
          ></v-btn>
        </div>
      </v-toolbar>
      <v-spacer></v-spacer>
    </div>

    <div
      id="sidebar"
      v-if="selectedField"
      class="bg-white"
      style="height: calc(100vh - 64px); width: 30%"
    >
      <!-- TODO: transformar sidebar em componente -->
      <v-card
        flat
        tile
        :title="selectedField.properties!.name"
        :subtitle="`${formatDecimal(selectedField.properties!.size_m2)} m²`"
        class="h-100"
      >
        <v-btn
          flat
          size="small"
          :ripple="false"
          icon="mdi-close"
          style="position: absolute; top: 0; right: 0"
          @click="deselectField"
        ></v-btn>

        <v-divider class="mx-4"></v-divider>

        <v-card-text class="hidden-overflow" style="height: calc(100% - 76px)">
          <FieldCasesRecord :fieldId="selectedField.id"></FieldCasesRecord>
          <FieldActivities
            :fieldId="selectedField.id"
            :key="selectedField.id"
          ></FieldActivities>
        </v-card-text>
      </v-card>
    </div>
  </div>

  <FormDialog
    title="Nova Área"
    titleIcon="mdi-shape-polygon-plus"
    submitText="Salvar"
    :open="showForm"
    :submit="submitForm"
    @close="showForm = false"
  >
    <v-text-field
      label="Nome"
      name="name"
      v-model="formData.name"
      :rules="[required]"
    >
    </v-text-field>
    <v-text-field
      readonly
      label="Área m²"
      name="size_m2"
      suffix="m²"
      :rules="[required]"
      v-model="sizem2Readable"
    >
    </v-text-field>
    <v-autocomplete
      :clearable="!organizationIdReadonly"
      label="Organização"
      auto-select-first
      name="organizations"
      v-model="formData.organization_id"
      autocomplete="off"
      :items="organizations"
      :loading="loadingOrganizations"
      item-title="name"
      item-value="id"
      validate-on="blur"
      :rules="[required]"
      :readonly="organizationIdReadonly"
    >
    </v-autocomplete>
    <v-text-field
      v-show="false"
      type="hidden"
      name="geometry"
      v-model="formData.geometry"
      :rules="[required]"
    ></v-text-field>
  </FormDialog>

  <FormDialog
    title="Editando Área"
    titleIcon="mdi-shape-polygon-plus"
    submitText="Salvar"
    :open="showEditForm"
    :submit="submitEditForm"
    @close="showEditForm = false"
  >
    <v-text-field
      label="Nome"
      name="name"
      v-model="editFormData.name"
      :rules="[required]"
    >
    </v-text-field>
  </FormDialog>
</template>

<script setup lang="ts">
import FormDialog from "@/components/FormDialog.vue";
import { useLoadOrganizations } from "@/composables/load-organizations";
import { useUsersStore } from "@/modules/users/store";
import { useSnackbar } from "@/store";
import { required } from "@/validators";
import area from "@turf/area";
import bbox from "@turf/bbox";
import center from "@turf/center";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import tippy from "tippy.js";
import { onMounted, reactive, ref, watch } from "vue";
import FieldActivities from "./FieldActivities.vue";
import FieldCasesRecord from "./FieldCasesRecord.vue";
import { useFieldsMap } from "./fieldsMap";

let fieldsGeojson: any;

const toggleVisualization = ref(0);
const toggleSubVisualization = ref(0);
const selectedField = ref();
const creatingField = ref(false);
const canFinishField = ref(false);
const showForm = ref(false);
const mapStyle = ref("satellite");

const { showSnackbar } = useSnackbar();
const userStore = useUsersStore();
const M = useFieldsMap();
const { editingField } = M;

const {
  organizations,
  loadingOrganizations,
  load: loadOrgs,
} = useLoadOrganizations();

onMounted(() => {
  tippy("[data-tippy-content]", { arrow: false });
  M.initMap(mapStyle.value, fetchData);
  M.bindMapEvent("click", selectField, M.FIELDS_POLYGON_LAYER);
});

watch(toggleVisualization, (val) => {
  toggleSubVisualization.value = 0;
  M.changeVisualization(val, toggleSubVisualization.value);
});

function changeMapStyle() {
  M.changeStyle(mapStyle.value);
}

function selectField(e: mapboxgl.MapLayerMouseEvent) {
  if (
    e.features &&
    e.features.length > 0 &&
    selectedField.value?.id !== e.features[0].id
  ) {
    selectedField.value = fieldsGeojson.features.find(
      (f: any) => f.id === e.features![0].id
    );
    M.map.value.fitBounds(
      bbox(selectedField.value) as [number, number, number, number],
      {
        padding: 100,
      }
    );
    M.hidePoints();
    M.replaceSelectedField(selectedField.value);
    M.showSelectedField();
  }
}

function deselectField() {
  selectedField.value = undefined;
  M.map.value.zoomOut();
  M.hideSelectedField();
  M.hidePoints();
  M.showFields();
}

function fetchData() {
  const fieldsreq = axios.get("/fields/geojson").then((res) => {
    M.replaceFields(res.data);
    fieldsGeojson = res.data;
    return res.data;
  });

  axios.get("/stations/geojson").then((res) => {
    M.replaceStations(res.data);
  });

  const orgsreq = axios.get("/organizations/geojson").then((res) => {
    M.replaceOrgs(res.data);
    return res.data;
  });

  axios
    .get("/placevisits/geojson", { params: { latest_by_address: true } })
    .then((res) => {
      M.replacePlacevisits(res.data);
    });

  Promise.all([fieldsreq, orgsreq]).then((val) => {
    const fields = val[0];
    const orgs = val[1];

    if (orgs.features && orgs.features.length > 0) {
      const c = center(orgs.features[0]);
      M.centerMap(c.geometry.coordinates[1], c.geometry.coordinates[0]);
    } else if (fields.features && fields.features.length > 0) {
      const c = center(fields.features[0]);
      M.centerMap(c.geometry.coordinates[1], c.geometry.coordinates[0]);
    }
  });

  M.changeVisualization(
    toggleVisualization.value,
    toggleSubVisualization.value
  );
}

const formDataDefault = {
  name: "",
  size_m2: 0,
  organization_id: undefined,
  geometry: "",
};
const formData = reactive({ ...formDataDefault });
const organizationIdReadonly = ref(false);
const sizem2Readable = ref("");

function drawField() {
  M.draw.value.changeMode("draw_polygon");
  canFinishField.value = false;
  creatingField.value = true;
  selectedField.value = undefined;
  M.unbindMapEvent("click", selectField, M.FIELDS_POLYGON_LAYER);
  M.bindMapEvent("draw.create", onDrawCreate);
  M.bindMapEvent("draw.update", onDrawCreateUpdate);
}

function cancelDrawingField() {
  M.draw.value.changeMode("simple_select");
  M.draw.value.deleteAll();
  creatingField.value = false;
  M.bindMapEvent("click", selectField, M.FIELDS_POLYGON_LAYER);
  M.unbindMapEvent("draw.create", onDrawCreate);
  M.unbindMapEvent("draw.update", onDrawCreateUpdate);
}

function onDrawCreate(e: any) {
  if (e.features.length > 0) {
    Object.assign(formData, formDataDefault);
    organizationIdReadonly.value = false;
    formData.geometry = e.features[0].geometry;
    formData.size_m2 = area(e.features[0]);
    const org_id = M.checkWithinOrg(e.features[0]);
    if (org_id) {
      formData.organization_id = org_id;
      organizationIdReadonly.value = true;
    }
    sizem2Readable.value = formatDecimal(formData.size_m2);
    canFinishField.value = true;
  }
}

function onDrawCreateUpdate(e: any) {
  if (e.features.length > 0) {
    formData.geometry = e.features[0].geometry;
    formData.size_m2 = area(e.features[0]);
    sizem2Readable.value = formatDecimal(formData.size_m2);
  }
}

function finishField() {
  loadOrgs();
  showForm.value = true;
}

async function submitForm() {
  try {
    const res = await axios.post(`/fields`, formData);
    fieldsGeojson.features.push(res.data);
    M.replaceFields(fieldsGeojson);
    showForm.value = false;
    creatingField.value = false;
  } catch (err) {
    showSnackbar("Erro ao criar nova Área", "error");
  } finally {
    M.unbindMapEvent("draw.create", onDrawCreate);
    M.unbindMapEvent("draw.update", onDrawCreateUpdate);
    M.draw.value.deleteAll();
    M.bindMapEvent("click", selectField, M.FIELDS_POLYGON_LAYER);
  }
}

const editFormDataDefault = {
  name: undefined,
};
const editFormData = reactive({ ...editFormDataDefault });
const showEditForm = ref(false);
const editGeomDataDefault = {
  geom: undefined,
  size_m2: 0,
};
const editGeomData = reactive({ ...editGeomDataDefault });

function editForm() {
  Object.assign(editFormData, editFormDataDefault);
  sizem2Readable.value = formatDecimal(selectedField.value.properties.size_m2);
  editFormData.name = selectedField.value.properties.name;
  showEditForm.value = true;
}

async function submitEditForm() {
  try {
    const res = await axios.patch(
      `/fields/${selectedField.value.id}`,
      editFormData
    );
    const idx = fieldsGeojson.features.findIndex(
      (f: any) => f.id === res.data.id
    );
    fieldsGeojson.features[idx] = res.data;
    M.replaceFields(fieldsGeojson);
    selectedField.value = res.data;
    showEditForm.value = false;
  } catch (err) {
    showSnackbar("Erro ao editar Área", "error");
  }
}

function editField() {
  M.draw.value.add(selectedField.value);
  M.draw.value.changeMode("simple_select", {
    featureIds: [selectedField.value.id.toString()],
  });
  M.map.value.zoomOut();
  canFinishField.value = false;
  editingField.value = true;
  M.bindMapEvent("draw.update", onDrawEditUpdate);
  M.hideFields();
  M.hideSelectedField();
  M.hidePoints();
}

function onDrawEditUpdate(e: any) {
  if (e.features.length > 0) {
    editGeomData.geom = e.features[0].geometry;
    editGeomData.size_m2 = area(e.features[0]);
    sizem2Readable.value = formatDecimal(formData.size_m2);
    canFinishField.value = true;
  }
}

function cancelEditingField() {
  M.draw.value.changeMode("simple_select");
  M.draw.value.deleteAll();
  M.showFields();
  M.showSelectedField();
  M.map.value.fitBounds(
    bbox(selectedField.value) as [number, number, number, number],
    {
      padding: 100,
    }
  );
  editingField.value = false;
  M.unbindMapEvent("draw.update", onDrawEditUpdate);
}

async function finishEditingField() {
  try {
    const res = await axios.patch(`/fields/${selectedField.value.id}/geom`, {
      geometry: editGeomData.geom,
      size_m2: editGeomData.size_m2,
    });
    const idx = fieldsGeojson.features.findIndex(
      (f: any) => f.id === res.data.id
    );
    fieldsGeojson.features[idx] = res.data;
    selectedField.value = res.data;
    M.replaceSelectedField(selectedField.value);
    M.replaceFields(fieldsGeojson);
  } catch (err) {
    showSnackbar("Erro ao editar Área", "error");
  } finally {
    M.draw.value.changeMode("simple_select");
    M.draw.value.deleteAll();
    M.showFields();
    M.showSelectedField();
    editingField.value = false;
    M.unbindMapEvent("draw.update", onDrawEditUpdate);
  }
}

function formatDecimal(value: number) {
  return value.toLocaleString("pt-BR", {
    maximumFractionDigits: 2,
  });
}
</script>

<style scoped lang="scss">
// #sidebar {
//   scrollbar-gutter: stable;
//}
.hidden-overflow {
  overflow-y: auto;
}

.hidden-overflow::-webkit-scrollbar {
  display: none;
  width: 0px;
  background-color: #e4e4e4; /* or add it to the track */
}
// #sidebar::-webkit-scrollbar-thumb {
//   background: #aaa;
// }
</style>
