<template>
  <v-card title="Aplicação" :variant="'flat'">
    <template v-slot:subtitle>
      <div>
        {{ application.executions.length }}/{{ application.total_cycles }} -
        <span
          v-if="
            !application.activity.finish_date &&
            application.executions.length == 0
          "
        >
          {{
            `Início em ${dayjs(application.activity.start_date).format(
              "DD/MM/YYYY"
            )} (${
              dayjs(application.activity.start_date).isSame(dayjs(), "day")
                ? "hoje"
                : dayjs(application.activity.start_date).fromNow()
            })`
          }}
        </span>
        <span
          v-if="
            application.executions.length > 0 &&
            !application.activity.finish_date
          "
        >
          {{
            `Próxima em ${nextExecution.format("DD/MM/YYYY")} (${
              nextExecution.isSame(dayjs(), "day")
                ? "hoje"
                : nextExecution.fromNow()
            })`
          }}
        </span>
        <span v-if="application.activity.finish_date">
          {{
            `Finalizada em ${dayjs(application.activity.finish_date)
              .locale("pt-BR")
              .format("DD/MM/YYYY")}`
          }}
        </span>
      </div>
    </template>
    <template v-slot:prepend>
      <v-progress-circular
        :model-value="
          (application.executions.length / application.total_cycles) * 100
        "
        :size="36"
        :width="18"
        :bg-color="
          application.activity.finish_date
            ? 'green-lighten-5'
            : 'indigo-lighten-5'
        "
        :color="application.activity.finish_date ? 'green' : 'indigo'"
      >
      </v-progress-circular>
    </template>
    <template
      v-if="(canCreate && !application.activity.finish_date) || canDelete"
      v-slot:append
    >
      <v-btn
        icon="mdi-dots-vertical"
        variant="text"
        size="small"
        style="position: absolute; right: 0; top: 0; margin: 0.5rem"
      >
        <v-icon icon="mdi-dots-vertical"></v-icon>
        <v-menu activator="parent">
          <v-list>
            <v-list-item
              v-if="!application.activity.finish_date && canCreate"
              prepend-icon="mdi-pencil"
              @click="emits('edit', application)"
            >
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!application.activity.finish_date && canCreate"
              prepend-icon="mdi-check"
              @click="emits('finish', application)"
            >
              <v-list-item-title>Finalizar</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="application.executions.length == 0 && canDelete"
              base-color="error"
              prepend-icon="mdi-delete"
              @click="emits('delete', application)"
            >
              <v-list-item-title>Excluir</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </template>
    <v-card-text class="pb-0">
      <v-row>
        <v-col
          ><p>
            <v-icon
              class="text-medium-emphasis mr-1"
              icon="mdi-flask"
              style="vertical-align: bottom"
              size="large"
              data-tippy-content="Produto"
            ></v-icon>
            {{ application.product.name }}
          </p>
          <p class="mt-1">
            <v-icon
              class="text-medium-emphasis mr-1"
              icon="mdi-engine"
              style="vertical-align: bottom"
              size="large"
              data-tippy-content="Equipamento"
            ></v-icon>
            {{ application.equipment.name }}
          </p></v-col
        >
        <v-col>
          <p>
            <v-icon
              class="text-medium-emphasis mr-1"
              icon="mdi-sun-clock"
              style="vertical-align: bottom"
              size="large"
              data-tippy-content="Intervalo de Aplicação"
            ></v-icon>
            {{ application.cycle_interval_days }} dias
          </p>
          <p class="mt-1">
            <v-icon
              class="text-medium-emphasis mr-1"
              icon="mdi-spray"
              style="vertical-align: bottom"
              size="large"
              data-tippy-content="Taxa de Aplicação"
            ></v-icon>
            {{ SimpleMaskMoney.formatToCurrency(application.application_rate) }}
            <span class="text-caption">{{ application.application_unit }}</span>
          </p>
        </v-col>
      </v-row>
      <v-expand-transition>
        <div v-show="showMore" class="mt-4">
          <v-timeline align="start" density="compact" truncate-line="both">
            <v-timeline-item
              v-for="execution in application.executions"
              :key="execution.id"
              dot-color="success"
              size="small"
            >
              <template v-slot:icon>
                <v-icon
                  icon="mdi-record"
                  :color="execution === selectedExecution ? 'white' : 'success'"
                  @click="selectExecution(execution)"
                ></v-icon>
              </template>
              <div>
                <div>
                  <strong class="text-body-1 font-weight-bold">{{
                    dayjs(execution.started_at).format("DD/MM/YYYY")
                  }}</strong>
                  ({{
                    dayjs(execution.started_at).isSame(dayjs(), "day")
                      ? "hoje"
                      : dayjs(execution.started_at).fromNow()
                  }})
                </div>
              </div>
              <div>
                <v-icon
                  class="text-medium-emphasis mr-1"
                  icon="mdi-account"
                  style="vertical-align: bottom"
                  data-tippy-content="Executor"
                ></v-icon>
                {{ execution.executor.full_name }}
              </div>
              <div>
                <v-icon
                  class="text-medium-emphasis mr-1"
                  icon="mdi-timer-play-outline"
                  style="vertical-align: bottom"
                  :data-tippy-content="
                    'Duração ' +
                    differenceInHoursMinutes(
                      execution.started_at,
                      execution.finished_at
                    )
                  "
                ></v-icon>
                {{ dayjs(execution.started_at).format("HH:mm") }}
                -
                {{ dayjs(execution.finished_at).format("HH:mm") }}
              </div>
              <div
                v-if="execution.weather_conditions"
                :data-tippy-content="
                  execution.weather_conditions_percentage != null
                    ? execution.weather_conditions_percentage +
                      '% em Condições ideais'
                    : 'Condições de aplicação'
                "
              >
                <v-icon
                  class="text-medium-emphasis mr-1"
                  icon="mdi-windsock"
                  style="vertical-align: bottom"
                ></v-icon>
                Iniciado em {{ execution.weather_conditions }}
              </div>
              <div>
                <v-icon
                  class="text-medium-emphasis mr-1"
                  icon="mdi-speedometer"
                  style="vertical-align: bottom"
                  data-tippy-content="Velocidade média"
                ></v-icon>
                {{ execution.average_speed.toFixed(1).replace(".", ",") }}
                km/h
              </div>
              <div
                v-if="execution.flowmeter_work"
                @click="showFlowmeterWork(execution)"
                class="cursor-pointer"
              >
                <v-icon
                  class="text-medium-emphasis mr-1"
                  icon="mdi-thermometer-water"
                  style="vertical-align: bottom"
                  data-tippy-content="Leituras do fluxômetro"
                ></v-icon>
                Fluxômetro
              </div>
            </v-timeline-item>
            <v-timeline-item
              v-for="d in futureExecutions(application)"
              :key="d.format()"
              dot-color="white"
              size="x-small"
            >
              <div>
                <div>
                  <span class="font-weight-medium">{{
                    d.locale("pt-BR").format("DD/MM/YYYY")
                  }}</span>
                  ({{ d.isSame(dayjs(), "day") ? "hoje" : d.fromNow() }})
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-expand-transition>
    </v-card-text>
    <v-card-actions>
      <v-btn
        block
        :append-icon="showMore ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        @click="showMore = !showMore"
        color="primary"
        :text="showMore ? 'Menos detalhes' : 'Mais detalhes'"
      ></v-btn>
    </v-card-actions>
  </v-card>

  <v-dialog
    v-model="flowmeterDialog"
    max-width="600"
    transition="dialog-transition"
    :persistent="true"
    retain-focus
    @keyup.esc="flowmeterDialog = false"
  >
    <v-card>
      <v-card-title>Leituras do fluxômetro</v-card-title>
      <v-btn
        icon="mdi-close"
        tabindex="-1"
        variant="text"
        @click="flowmeterDialog = false"
        style="position: absolute; right: 0; top: 0; margin: 0.5rem"
      >
      </v-btn>
      <v-card-text>
        <v-table density="compact" v-if="flowmeterWork">
          <thead>
            <tr>
              <th class="text-center">Ponta</th>
              <th class="text-center">Leitura</th>
              <th class="text-center">Releitura</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(reading, idx) in flowmeterWork.readings" :key="idx">
              <td class="text-center">
                <b>P{{ reading.ponta }}</b>
              </td>
              <td class="text-center">
                {{ reading.leitura.toFixed(2).replace(".", ",") }}
                <v-icon
                  v-if="reading.status == 'obstructed'"
                  icon="mdi-arrow-down-box"
                  color="warning"
                ></v-icon>
                <v-icon
                  v-else-if="reading.status == 'worn'"
                  icon="mdi-arrow-up-box"
                  color="error"
                ></v-icon>
                <v-icon
                  v-else
                  icon="mdi-checkbox-marked"
                  color="success"
                ></v-icon>
              </td>
              <td class="text-center">
                <div v-if="reading.releitura >= 0">
                  {{ reading.releitura.toFixed(2).replace(".", ",") }}
                  <v-icon
                    v-if="reading.restatus == 'obstructed'"
                    icon="mdi-arrow-down-box"
                    color="warning"
                  ></v-icon>
                  <v-icon
                    v-else-if="reading.restatus == 'worn'"
                    icon="mdi-arrow-up-box"
                    color="error"
                  ></v-icon>
                  <v-icon
                    v-else
                    icon="mdi-checkbox-marked"
                    color="success"
                  ></v-icon>
                </div>
                <span v-else>--</span>
              </td>
              <td class="text-center">
                {{ flowmeterWork.unit }}
              </td>
            </tr>
          </tbody>
        </v-table>
        <p class="text-caption d-flex justify-space-evenly">
          <span>
            Vazão de Referência:
            {{ flowmeterWork.tips_ref_flow_rate.toFixed(2).replace(".", ",")
            }}{{ flowmeterWork.unit }}
          </span>
          <span>
            Coeficiente de Variação:
            {{ (flowmeterWork.coefficient_of_variation * 100).toFixed(0) }}%
          </span>
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import axios from "axios";
import dayjs from "dayjs";
import SimpleMaskMoney from "simple-mask-money";
import { ref, watch } from "vue";
import { useFieldsMap } from "./fieldsMap";
import { useUsersStore } from "@/modules/users/store";
import { computed } from "vue";

const props = defineProps<{
  application: any;
  nextExecution: dayjs.Dayjs;
}>();

const emits = defineEmits<{
  (e: "edit", application: any): void;
  (e: "finish", application: any): void;
  (e: "delete", application: any): void;
}>();

const showMore = ref(false);
const M = useFieldsMap();
const { editingField } = useFieldsMap();
const userStore = useUsersStore();

const canCreate = computed(() => {
  return (
    userStore.userMe?.is_admin || userStore.userMe?.hierarchy.create_permission
  );
});

const canDelete = computed(() => {
  return (
    userStore.userMe?.is_admin || userStore.userMe?.hierarchy.delete_permission
  );
});

function futureExecutions(application: any) {
  const dates = [];
  const nExecutions = application.executions.length;
  const n = application.total_cycles - nExecutions;
  let date =
    nExecutions > 0
      ? dayjs(application.executions.at(-1).started_at)
      : dayjs(application.activity.start_date).subtract(
          application.cycle_interval_days,
          "day"
        );
  for (let i = 0; i < n; i++) {
    date = date.add(application.cycle_interval_days, "day");
    dates.push(date);
  }
  return dates;
}

function differenceInHoursMinutes(dateString1: string, dateString2: string) {
  const date1 = dayjs(dateString1);
  const date2 = dayjs(dateString2);
  const diff = date2.diff(date1, "minute");
  if (diff < 60) return `${diff}m`;
  if (diff < 60 * 24) return `${Math.floor(diff / 60)}h ${diff % 60}m`;
  return `${Math.floor(diff / 60 / 24)}d ${Math.floor((diff / 60) % 24)}h ${
    diff % 60
  }m`;
}

const selectedExecution = ref();

watch(editingField, () => {
  if (editingField.value) deselectExecution();
});

function selectExecution(execution: any) {
  if (selectedExecution.value === execution) {
    deselectExecution();
    return;
  }
  selectedExecution.value = execution;
  fetchPoints(execution);
}

function deselectExecution() {
  selectedExecution.value = undefined;
  M.hidePoints();
}

async function fetchPoints(execution: any) {
  try {
    const res = await axios.get(
      `/application-executions/${execution.id}/points/geojson`
    );
    M.replacePoints(res.data.points, res.data.route);
    M.showPoints();
  } catch (err) {
    console.log(err);
  }
}

const flowmeterDialog = ref(false);
const flowmeterWork = ref<any>();

function showFlowmeterWork(execution: any) {
  flowmeterWork.value = execution.flowmeter_work;
  flowmeterDialog.value = true;
}

function higher5Percent(reading: number, reference: number): boolean {
  return reading > reference + reference * 0.05;
}

function lower5Percent(reading: number, reference: number): boolean {
  return reading < reference - reference * 0.05;
}
</script>
