<template>
  <v-dialog v-model="open" max-width="400" persistent>
    <v-card :title="title" :text="text">
      <template v-slot:prepend>
        <v-icon color="error" icon="mdi-delete"></v-icon>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn prepend-icon="mdi-close" color="primary" @click="open = false">
          Cancelar
        </v-btn>

        <v-btn prepend-icon="mdi-delete" color="error" @click="emits('delete')">
          Confirmar
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
// eslint-disable-next-line
const open = defineModel("open", {
  required: false,
  type: Boolean,
  default: false,
});

const props = defineProps<{
  title: string;
  text?: string;
}>();

const emits = defineEmits<{
  (e: "delete"): void;
}>();
</script>
