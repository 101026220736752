<template>
  <v-card title="Equipamentos">
    <template v-slot:prepend>
      <v-avatar>
        <v-icon color="primary" icon="mdi-engine"></v-icon>
      </v-avatar>
    </template>
    <v-card-text>
      <CRUDTable
        v-model:create-form-data="createFormData"
        v-model:edit-form-data="editFormData"
        :url-base="urlBase"
        :messages="crudMsgs"
        :create-config="createConfig"
        :edit-config="editConfig"
        :headers="tableHeaders"
        :items-process="equipmentsProcess"
        @create-opened="getTypes"
        @edit-opened="getTypes"
      >
        <template v-slot:createForm>
          <v-text-field
            label="Nome"
            name="name"
            :error-messages="nameError"
            v-model="createFormData.name"
            validate-on="blur"
            :rules="[required]"
            autofocus
            ref="nameInput"
          ></v-text-field>
          <v-autocomplete
            label="Tipo"
            name="type_id"
            autocomplete="off"
            v-model="createFormData.type_id"
            :items="types"
            item-title="name"
            item-value="id"
            :loading="loadingTypes"
            :rules="[required]"
          ></v-autocomplete>
        </template>

        <template v-slot:editForm>
          <v-text-field
            label="Nome"
            name="name"
            :error-messages="nameError"
            v-model="editFormData.name"
            validate-on="blur"
            :rules="[required]"
            autofocus
            ref="nameInput"
          ></v-text-field>
          <v-autocomplete
            label="Tipo"
            name="type_id"
            autocomplete="off"
            v-model="editFormData.type_id"
            :items="types"
            item-title="name"
            item-value="id"
            :loading="loadingTypes"
            :rules="[required]"
          ></v-autocomplete>
        </template>
      </CRUDTable>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import CRUDTable from "@/components/CRUDTable.vue";
import { useSnackbar } from "@/store";
import { required } from "@/validators";
import { watchOnce } from "@vueuse/core";
import axios, { AxiosError } from "axios";
import { reactive, ref } from "vue";

const { showSnackbar } = useSnackbar();

const createFormDataDefault = {
  name: "",
  type_id: undefined,
};
const editFormDataDefault = {
  name: "",
  type_id: undefined,
};
const createFormData = reactive({ ...createFormDataDefault });
const editFormData = reactive({ ...editFormDataDefault });
const urlBase = "/admin/equipments";
const crudMsgs = {
  unexpectedError:
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.",
  createSuccess: "Equipamento criado com sucesso!",
  editSuccess: "Equipamento editado com sucesso!",
};
const createConfig = {
  btnText: "Novo Equipamento",
  btnIcon: "mdi-plus",
  formTitle: "Novo Equipamento",
  formIcon: "mdi-engine",
  submitText: "Salvar",
  formDataDefault: createFormDataDefault,
  handleCreateError: checkExistingName,
};
const editConfig = {
  btnText: "Editar Equipamento",
  formTitle: "Editando Equipamento",
  formIcon: "mdi-engine",
  submitText: "Salvar",
  formDataDefault: editFormDataDefault,
  handleEditError: checkExistingName,
};
const tableHeaders = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Nome",
    key: "name",
  },
  {
    title: "Tipo",
    key: "type.name",
  },
  {
    title: "Ações",
    key: "actions",
    sortable: false,
    align: "end",
  },
];

const existingNameMsg = "Equipamento com este nome já existe";
const nameError = ref("");
const nameInput = ref();

function checkExistingName(err: AxiosError<any, any>) {
  if (err.response?.status === 409) {
    nameError.value = existingNameMsg;
    nameInput.value.focus();
    watchOnce(
      () => createFormData.name,
      () => (nameError.value = "")
    );
    return true;
  }
  return false;
}

function equipmentsProcess(equipments: any[]) {
  return equipments.map((e) => {
    e.type_id = e.type.id;
    return e;
  });
}

const loadingTypes = ref(false);
const types = ref<any[]>([]);
async function getTypes() {
  try {
    loadingTypes.value = true;
    const res = await axios.get("/admin/equipment-types");
    types.value = res.data;
  } catch (err) {
    showSnackbar(crudMsgs.unexpectedError, "error");
  } finally {
    loadingTypes.value = false;
  }
}
</script>
