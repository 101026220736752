<template>
  <v-container fluid class="bg-white">
    <CRUDTable
      v-model:create-form-data="createFormData"
      v-model:edit-form-data="editFormData"
      :url-base="urlBase"
      :messages="crudMsgs"
      :create-config="createConfig"
      :edit-config="editConfig"
      :headers="tableHeaders"
      :items-process="usersProcess"
      @create-opened="loadAll"
      @edit-opened="loadAll"
    >
      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon
          :icon="item.is_active ? 'mdi-check' : 'mdi-cancel'"
          :color="item.is_active ? 'success' : 'error'"
        ></v-icon>
      </template>
      <template v-slot:[`item.is_admin`]="{ item }">
        <v-icon
          :icon="item.is_admin ? 'mdi-check' : 'mdi-cancel'"
          :color="item.is_admin ? 'success' : 'error'"
        ></v-icon>
      </template>
      <template v-slot:[`item.organizations`]="{ item }">
        <v-chip v-if="item.organizations.length === 0">Nenhuma</v-chip>
        <v-menu
          v-if="item.organizations.length > 0"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ props }">
            <v-chip value="org.id" v-bind="props"
              >Ver {{ item.organizations.length }}</v-chip
            >
          </template>
          <v-list>
            <v-list-item v-for="org in item.organizations" :key="org.id">
              <v-list-item-title>{{ org.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:createForm>
        <v-text-field
          ref="emailInput"
          type="email"
          label="Email"
          placeholder="seu@email.com"
          name="email"
          :error-messages="emailError"
          v-model="createFormData.email"
          validate-on="blur"
          :rules="[required, validateEmail]"
          autofocus
        ></v-text-field>
        <v-text-field
          :type="passwordPeek ? 'text' : 'password'"
          label="Senha"
          autocomplete="off"
          name="password"
          v-model="createFormData.password"
          validate-on="blur"
          :rules="[required, validatePassword]"
        >
          <template v-slot:append-inner>
            <v-btn
              variant="plain"
              density="compact"
              :icon="passwordPeek ? 'mdi-eye' : 'mdi-eye-off'"
              tabindex="-1"
              @click="passwordPeek = !passwordPeek"
            ></v-btn>
          </template>
        </v-text-field>
        <v-text-field
          label="Nome Completo"
          name="full_name"
          v-model="createFormData.full_name"
          validate-on="blur"
          :rules="[required]"
        ></v-text-field>
        <v-autocomplete
          clearable
          label="Hierarquia"
          auto-select-first
          name="hierarchy"
          v-model="createFormData.hierarchy_id"
          autocomplete="off"
          :items="hierarchies"
          :loading="loadingHierarchies"
          item-title="name"
          item-value="id"
          validate-on="blur"
        ></v-autocomplete>
        <v-autocomplete
          clearable
          label="Org. Principal"
          auto-select-first
          name="organization"
          v-model="createFormData.main_organization_id"
          autocomplete="off"
          :items="organizations"
          :loading="loadingOrganizations"
          item-title="name"
          item-value="id"
          validate-on="blur"
        ></v-autocomplete>
        <v-autocomplete
          clearable
          label="Organizações"
          auto-select-first
          name="organizations"
          v-model="createFormData.organizations_ids"
          autocomplete="off"
          :items="organizations"
          :loading="loadingOrganizations"
          item-title="name"
          item-value="id"
          validate-on="blur"
          multiple
        ></v-autocomplete>
        <div class="d-flex justify-space-around">
          <v-checkbox
            label="Ativo"
            name="is_active"
            v-model="createFormData.is_active"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Admin"
            name="is_admin"
            v-model="createFormData.is_admin"
            hide-details
            single-line
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:editForm>
        <v-text-field
          ref="emailInput"
          type="email"
          label="Email"
          placeholder="seu@email.com"
          name="email"
          :error-messages="emailError"
          v-model="editFormData.email"
          validate-on="blur"
          :rules="[required, validateEmail]"
          autofocus
        ></v-text-field>
        <v-text-field
          :type="passwordPeek ? 'text' : 'password'"
          label="Senha"
          autocomplete="off"
          name="password"
          v-model="editFormData.password"
          validate-on="blur"
          :rules="[validatePassword]"
        >
          <template v-slot:append-inner>
            <v-btn
              variant="plain"
              density="compact"
              :icon="passwordPeek ? 'mdi-eye' : 'mdi-eye-off'"
              tabindex="-1"
              @click="passwordPeek = !passwordPeek"
            ></v-btn>
          </template>
        </v-text-field>
        <v-text-field
          label="Nome Completo"
          name="full_name"
          v-model="editFormData.full_name"
          validate-on="blur"
          :rules="[required]"
        ></v-text-field>
        <v-autocomplete
          clearable
          label="Hierarquia"
          auto-select-first
          name="hierarchy"
          v-model="editFormData.hierarchy_id"
          autocomplete="off"
          :items="hierarchies"
          :loading="loadingHierarchies"
          item-title="name"
          item-value="id"
          validate-on="blur"
        ></v-autocomplete>
        <v-autocomplete
          clearable
          label="Org. Principal"
          auto-select-first
          name="organization"
          v-model="editFormData.main_organization_id"
          autocomplete="off"
          :items="organizations"
          :loading="loadingOrganizations"
          item-title="name"
          item-value="id"
          validate-on="blur"
        ></v-autocomplete>
        <v-autocomplete
          clearable
          label="Organizações"
          auto-select-first
          name="organizations"
          v-model="editFormData.organizations_ids"
          autocomplete="off"
          :items="organizations"
          :loading="loadingOrganizations"
          item-title="name"
          item-value="id"
          validate-on="blur"
          multiple
        ></v-autocomplete>
        <div class="d-flex justify-space-around">
          <v-checkbox
            label="Ativo"
            name="is_active"
            v-model="editFormData.is_active"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Admin"
            name="is_admin"
            v-model="editFormData.is_admin"
            hide-details
            single-line
          ></v-checkbox>
        </div>
      </template>
    </CRUDTable>
  </v-container>
</template>

<script setup lang="ts">
import CRUDTable, {
  CreateConfig,
  EditConfig,
  Messages,
} from "@/components/CRUDTable.vue";
import { localeDateTimeOptions } from "@/consts";
import { useSnackbar } from "@/store";
import { required, validateEmail, validatePassword } from "@/validators";
import { watchOnce } from "@vueuse/core";
import { AxiosError } from "axios";
import { reactive, ref } from "vue";
import { useLoadOrganizations, useLoadHierarchies } from "./composables";

const { showSnackbar } = useSnackbar();

const defaultCreateFormData = {
  email: "",
  password: "",
  full_name: "",
  hierarchy_id: undefined,
  organizations_ids: [],
  main_organization_id: undefined,
  is_active: true,
  is_admin: false,
};
const defaultEditFormData = {
  email: "",
  password: undefined,
  full_name: "",
  hierarchy_id: undefined,
  organizations_ids: [],
  main_organization_id: undefined,
  is_active: true,
  is_admin: false,
};
const createFormData = reactive({ ...defaultCreateFormData });
const editFormData = reactive({ ...defaultEditFormData });

const urlBase = "/admin/users";
const crudMsgs: Messages = {
  unexpectedError:
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.",
  createSuccess: "Usuário criado com sucesso!",
  editSuccess: "Usuário editado com sucesso!",
};
const createConfig: CreateConfig = {
  btnText: "Novo Usuário",
  btnIcon: "mdi-plus",
  formTitle: "Novo Usuário",
  formIcon: "mdi-briefcase-plus",
  submitText: "Salvar",
  formDataDefault: defaultCreateFormData,
  handleCreateError: checkExistingEmail,
};
const editConfig: EditConfig = {
  btnText: "Editar Usuário",
  formTitle: "Editando Usuário",
  formIcon: "mdi-briefcase-edit",
  submitText: "Salvar",
  formDataDefault: defaultEditFormData,
  handleEditError: checkExistingEmail,
};

const tableHeaders = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Nome Completo",
    key: "full_name",
  },
  {
    title: "Email",
    key: "email",
  },
  {
    title: "Org. Principal",
    key: "main_organization.name",
  },
  {
    title: "Organizações",
    key: "organizations",
  },
  {
    title: "Hierarquia",
    key: "hierarchy.name",
  },
  {
    title: "Criação",
    key: "created_at",
  },
  {
    title: "Ativo",
    key: "is_active",
  },
  {
    title: "Admin",
    key: "is_admin",
  },
  {
    title: "Ações",
    key: "actions",
    sortable: false,
    align: "end",
  },
];

const emailInput = ref();
const emailError = ref("");
const passwordPeek = ref(false);

function checkExistingEmail(err: AxiosError<any, any>) {
  if (err.response?.status === 409) {
    emailError.value = "Usuário com esse email já existe.";
    emailInput.value.focus();
    watchOnce(
      () => createFormData.email,
      () => (emailError.value = "")
    );
    return true;
  }
  return false;
}

const {
  organizations,
  loadingOrganizations,
  load: loadOrgs,
} = useLoadOrganizations();

const {
  hierarchies,
  loadingHierarchies,
  load: loadHierarchies,
} = useLoadHierarchies();

async function loadAll() {
  await Promise.all([loadOrgs(), loadHierarchies()]);
}

function usersProcess(users: any[]) {
  return users.map((user: any) => {
    user.created_at = new Date(user.created_at).toLocaleString(
      undefined,
      localeDateTimeOptions
    );
    user.hierarchy_id = user.hierarchy?.id;
    user.main_organization_id = user.main_organization?.id;
    user.organizations_ids = user.organizations.map((o: any) => o.id);
    return user;
  });
}
</script>
