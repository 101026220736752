<template>
  <div class="d-flex align-center justify-space-between">
    <span class="text-h5">Atividades</span>
    <v-btn
      v-if="userStore.canCreate"
      prepend-icon="mdi-plus"
      variant="tonal"
      color="primary"
      text="Nova"
      @click="initCreate"
    ></v-btn>
  </div>

  <div>
    <div
      v-if="loadingActivities"
      class="text-center text-caption text-medium-emphasis my-2"
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="primary"
        class="my-2"
      ></v-progress-linear>
    </div>

    <div v-if="!loadingActivities">
      <div v-if="applications?.length > 0">
        <template v-for="a in applications" :key="a.id">
          <FieldActivity
            :application="a"
            :next-execution="nextExecution(a)"
            @edit="initEdit"
            @finish="initFinish"
            @delete="openDeleteDialog"
          />
        </template>
      </div>

      <div
        v-if="applications?.length === 0"
        class="text-center text-caption text-medium-emphasis my-2"
      >
        <p>Nenhuma atividade registrada</p>
      </div>
    </div>
  </div>

  <FormDialog
    title="Nova Atividade"
    titleIcon="mdi-calendar-plus"
    submitText="Salvar"
    :open="openCreateForm"
    :form-error="formError"
    :submit="submitCreate"
    @close="closeCreate"
  >
    <v-text-field
      type="date"
      label="Data de Início"
      name="start_date"
      v-model="createFormData.start_date"
      validate-on="input"
      :rules="[required]"
      autofocus
    ></v-text-field>
    <div class="d-flex">
      <v-autocomplete
        class="w-50"
        clearable
        label="Produto"
        auto-select-first
        name="produto"
        v-model="createFormData.product_id"
        autocomplete="off"
        :items="products"
        :loading="loadingProducts"
        item-title="name"
        item-value="id"
        validate-on="input"
        :rules="[required]"
      ></v-autocomplete>
      <div class="ml-2"></div>
      <v-autocomplete
        class="w-50"
        clearable
        label="Equipamento"
        auto-select-first
        name="equipment"
        v-model="createFormData.equipment_id"
        autocomplete="off"
        :items="equipments"
        :loading="loadingEquipments"
        item-title="name"
        item-value="id"
        validate-on="input"
        :rules="[required]"
      ></v-autocomplete>
    </div>
    <div class="d-flex">
      <v-text-field
        class="w-50"
        type="number"
        label="Nº de Ciclos"
        name="total_cycles"
        v-model="createFormData.total_cycles"
        validate-on="input"
        :rules="[required]"
      ></v-text-field>
      <div class="ml-2"></div>
      <v-text-field
        class="w-50"
        type="number"
        label="Intervalo (dias)"
        name="cycle_interval_days"
        v-model="createFormData.cycle_interval_days"
        validate-on="input"
        :rules="[required]"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-text-field
        class="w-50"
        :hint="applicationRateHint"
        label="Taxa de Aplicação"
        name="application_rate"
        :model-value="
          SimpleMaskMoney.formatToCurrency(createFormData.application_rate)
        "
        @update:model-value="
          (value: any) =>
            (createFormData.application_rate =
              SimpleMaskMoney.formatToNumber(value))
        "
        v-simple-mask-money
        validate-on="input"
        :loading="loadingApplicationRate"
        :rules="[required]"
        @keydown.tab="unitInput.focus()"
      ></v-text-field>
      <div class="ml-2"></div>
      <v-text-field
        ref="unitInput"
        class="w-50"
        label="Unidade"
        placeholder="ml/min"
        name="application_unit"
        v-model="createFormData.application_unit"
        validate-on="input"
        :loading="loadingApplicationUnit"
        :rules="[required]"
      ></v-text-field>
    </div>
  </FormDialog>

  <FormDialog
    title="Editar Atividade"
    titleIcon="mdi-calendar-edit"
    submitText="Salvar"
    :open="openEditForm"
    :form-error="formError"
    :submit="submitEdit"
    @close="closeEdit"
  >
    <v-text-field
      type="date"
      label="Data de Início"
      name="start_date"
      v-model="editFormData.start_date"
      validate-on="input"
      :rules="[required]"
      autofocus
      disabled
    ></v-text-field>
    <div class="d-flex">
      <v-autocomplete
        class="w-50"
        label="Produto"
        auto-select-first
        name="produto"
        v-model="editFormData.product_id"
        autocomplete="off"
        :items="products"
        :loading="loadingProducts"
        item-title="name"
        item-value="id"
        validate-on="input"
        :rules="[required]"
        disabled
      ></v-autocomplete>
      <div class="ml-2"></div>
      <v-autocomplete
        class="w-50"
        label="Equipamento"
        auto-select-first
        name="equipment"
        v-model="editFormData.equipment_id"
        autocomplete="off"
        :items="equipments"
        :loading="loadingEquipments"
        item-title="name"
        item-value="id"
        validate-on="input"
        :rules="[required]"
        disabled
      ></v-autocomplete>
    </div>
    <div class="d-flex">
      <v-text-field
        class="w-50"
        type="number"
        label="Nº de Ciclos"
        name="total_cycles"
        v-model="editFormData.total_cycles"
        validate-on="input"
        :rules="[required, minCycles]"
      ></v-text-field>
      <div class="ml-2"></div>
      <v-text-field
        class="w-50"
        type="number"
        label="Intervalo (dias)"
        name="cycle_interval_days"
        v-model="editFormData.cycle_interval_days"
        validate-on="input"
        :rules="[required, minValue(1)]"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-text-field
        class="w-50"
        :hint="applicationRateHint"
        label="Taxa de Aplicação"
        name="application_rate"
        :model-value="
          SimpleMaskMoney.formatToCurrency(editFormData.application_rate)
        "
        @update:model-value="
          (value: any) =>
            (editFormData.application_rate =
              SimpleMaskMoney.formatToNumber(value))
        "
        v-simple-mask-money
        validate-on="input"
        :loading="loadingApplicationRate"
        :rules="[required]"
        @keydown.tab="unitInput.focus()"
        disabled
      ></v-text-field>
      <div class="ml-2"></div>
      <v-text-field
        ref="unitInput"
        class="w-50"
        label="Unidade"
        placeholder="ml/min"
        name="application_unit"
        v-model="editFormData.application_unit"
        validate-on="input"
        :loading="loadingApplicationUnit"
        :rules="[required]"
        disabled
      ></v-text-field>
    </div>
  </FormDialog>

  <DeleteDialog
    v-model:open="deleteDialog"
    title="Exclusão de Atividade"
    text="Deseja realmente excluir a atividade?"
    @delete="deleteActivity"
  ></DeleteDialog>

  <FormDialog
    title="Finalizar Atividade"
    titleIcon="mdi-check"
    submitText="Confirmar"
    :open="openFinishForm"
    :form-error="formError"
    :submit="submitFinish"
    @close="closeFinish"
  >
    <v-textarea
      label="Justificativa"
      name="start_date"
      v-model="finishFormData.observation"
      validate-on="input"
      :rules="[required]"
      autofocus
    ></v-textarea>
  </FormDialog>
</template>

<script setup lang="ts">
import DeleteDialog from "@/components/DeleteDialog.vue";
import FormDialog from "@/components/FormDialog.vue";
import { useUsersStore } from "@/modules/users/store";
import { useSnackbar } from "@/store";
import { required, minValue } from "@/validators";
import { watchOnce } from "@vueuse/core";
import axios from "axios";
import dayjs from "dayjs";
import SimpleMaskMoney from "simple-mask-money";
import tippy from "tippy.js";
import { computed, onBeforeMount, reactive, ref, watch } from "vue";
import FieldActivity from "./FieldActivity.vue";

const props = defineProps<{
  fieldId: number;
}>();

const { showSnackbar } = useSnackbar();
const userStore = useUsersStore();

const applications = ref<any[]>([]);
const loadingActivities = ref(false);

onBeforeMount(() => {
  listActivities(props.fieldId);
});

async function listActivities(fieldId: number) {
  loadingActivities.value = true;
  try {
    const res = await axios.get(`/fields/${fieldId}/application-activities`);
    applications.value = res.data.sort((a: any, b: any) => {
      if (a.activity.finish_date && !b.activity.finish_date) {
        return 1;
      } else if (!a.activity.finish_date && b.activity.finish_date) {
        return -1;
      } else if (a.activity.finish_date && b.activity.finish_date) {
        return dayjs(b.activity.finish_date).diff(
          dayjs(a.activity.finish_date)
        );
      }
      const now = dayjs();
      const aNext = nextExecution(a);
      const bNext = nextExecution(b);
      return aNext.diff(now, "day") - bNext.diff(now, "day");
    });
  } finally {
    loadingActivities.value = false;
    setTimeout(() => tippy("[data-tippy-content]", { arrow: false }), 1000);
  }
}

const messages = {
  createSuccess: "Atividade criada com sucesso",
  unexpectedError: "Ocorreu um erro inesperado. Tente novamente mais tarde.",
  productsError: "Ocorreu um erro ao carregar os produtos.",
  equipmentError: "Ocorreu um erro ao carregar os equipamentos.",
};

const formDataDefaults = {
  start_date: undefined,
  total_cycles: undefined,
  cycle_interval_days: undefined,
  product_id: undefined,
  equipment_id: undefined,
  application_rate: "",
  application_unit: undefined,
};
const createFormData = reactive({ ...formDataDefaults });
const openCreateForm = ref(false);
const formError = ref("");
const unitInput = ref();

function initCreate() {
  listProducts();
  listEquipments();
  openCreateForm.value = true;
}

async function submitCreate() {
  try {
    await axios.post(
      `/fields/${props.fieldId}/application-activities`,
      createFormData
    );
    closeCreate();
    showSnackbar(messages.createSuccess, "success");
    listActivities(props.fieldId);
  } catch (err) {
    handleSubmitError(err);
  }
}

function closeCreate() {
  Object.assign(createFormData, formDataDefaults);
  openCreateForm.value = false;
  formError.value = "";
}

watch(
  () => createFormData.product_id,
  () => {
    createFormData.total_cycles = products.value.find(
      (p) => p.id === createFormData.product_id
    )?.number_of_cycles;
    createFormData.cycle_interval_days = products.value.find(
      (p) => p.id === createFormData.product_id
    )?.cycle_interval_days;
  }
);

watch(
  [() => createFormData.product_id, () => createFormData.equipment_id],
  async () => {
    if (createFormData.product_id && createFormData.equipment_id) {
      getApplicationRate(createFormData, true);
    }
  }
);

const editFormData = reactive({ ...formDataDefaults });
const openEditForm = ref(false);
const editApplication = ref();
let unwatchEditProduct: () => void;
let unwatchEditProductEquipment: () => void;

function initEdit(application: any) {
  editApplication.value = application;
  Object.assign(editFormData, application);
  editFormData.start_date = application.activity.start_date;
  editFormData.product_id = application.product.id;
  editFormData.equipment_id = application.equipment.id;
  openEditForm.value = true;
  Promise.all([listProducts(), listEquipments()]).then(() => {
    getApplicationRate(editFormData);
  });

  unwatchEditProduct = watch(
    () => editFormData.product_id,
    () => {
      editFormData.total_cycles = products.value.find(
        (p) => p.id === editFormData.product_id
      )?.number_of_cycles;
      editFormData.cycle_interval_days = products.value.find(
        (p) => p.id === editFormData.product_id
      )?.cycle_interval_days;
    }
  );

  unwatchEditProductEquipment = watch(
    [() => editFormData.product_id, () => editFormData.equipment_id],
    async () => {
      if (editFormData.product_id && editFormData.equipment_id) {
        getApplicationRate(editFormData, true);
      }
    }
  );
}

async function submitEdit() {
  try {
    await axios.patch(
      `/application-activities/${editApplication.value.id}`,
      editFormData
    );
    closeEdit();
    showSnackbar(messages.createSuccess, "success");
    listActivities(props.fieldId);
  } catch (err) {
    handleSubmitError(err);
  }
}

function closeEdit() {
  unwatchEditProduct();
  unwatchEditProductEquipment();
  Object.assign(editFormData, formDataDefaults);
  openEditForm.value = false;
  editApplication.value = undefined;
  formError.value = "";
}

const products = ref<any[]>([]);
const loadingProducts = ref(false);
async function listProducts() {
  loadingProducts.value = true;
  try {
    const res = await axios.get("/application-products");
    products.value = res.data;
  } catch (err) {
    formError.value = messages.productsError;
  } finally {
    loadingProducts.value = false;
  }
}

const equipments = ref<any[]>([]);
const loadingEquipments = ref(false);
async function listEquipments() {
  loadingEquipments.value = true;
  try {
    const res = await axios.get("/application-equipments");
    equipments.value = res.data;
  } catch (err) {
    formError.value = messages.equipmentError;
  } finally {
    loadingEquipments.value = false;
  }
}

const loadingApplicationRate = ref(false);
const loadingApplicationUnit = ref(false);
const applicationRateHint = ref("");

async function getApplicationRate(formData: any, updateInputs = false) {
  console.log(equipments.value);

  loadingApplicationRate.value = true;
  loadingApplicationUnit.value = true;
  try {
    const res = await axios.get("/application-rate", {
      params: {
        product_id: formData.product_id,
        equipment_id: equipments.value.find(
          (e) => e.id === formData.equipment_id
        ).type.id,
      },
    });
    if (updateInputs) {
      formData.application_rate = res.data.rate_min;
      formData.application_unit = res.data.unit;
    }
    applicationRateHint.value = `Recomendação: ${
      res.data.rate_min != res.data.rate_max
        ? res.data.rate_min + " a " + res.data.rate_max
        : res.data.rate_min
    }`;
  } finally {
    loadingApplicationRate.value = false;
    loadingApplicationUnit.value = false;
  }
}

function handleSubmitError(err: any) {
  if (axios.isAxiosError(err)) {
    console.log(err.response?.data);
  }
  formError.value = messages.unexpectedError;
  watchOnce(
    () => createFormData,
    () => (formError.value = "")
  );
}

const finishFormDefaults = {
  observation: "",
};
const openFinishForm = ref(false);
const finishFormData = reactive({ ...finishFormDefaults });
const finishId = ref();

function initFinish(application: any) {
  finishId.value = application.id;
  Object.assign(finishFormData, finishFormDefaults);
  openFinishForm.value = true;
}

async function submitFinish() {
  try {
    await axios.post(`/application-activities/${finishId.value}/finish`, {
      finish_date: dayjs().startOf("day").utc(true),
      observation: finishFormData.observation,
    });
    listActivities(props.fieldId);
    showSnackbar("Atividade finalizada com sucesso", "success");
    closeFinish();
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 422) {
        showSnackbar("Obrigatório informar justificativa", "error");
      }
    }
    showSnackbar("Erro ao finalizar atividade", "error");
  }
}

function closeFinish() {
  Object.assign(finishFormData, finishFormDefaults);
  openFinishForm.value = false;
}

const deleteDialog = ref(false);
const deleteApplication = ref();

function openDeleteDialog(application: any) {
  deleteDialog.value = true;
  deleteApplication.value = application;
}

async function deleteActivity() {
  try {
    await axios.delete(`/application-activities/${deleteApplication.value.id}`);
    listActivities(props.fieldId);
    showSnackbar("Atividade excluida com sucesso", "success");
  } catch (err) {
    if (axios.isAxiosError(err)) {
      showSnackbar(err.response?.data.detail, "error");
      return;
    }
    showSnackbar("Erro ao excluir atividade", "error");
  } finally {
    deleteDialog.value = false;
  }
}

function minCycles(v: number) {
  const exs = editApplication.value.executions.length;
  return (
    v >= exs || `Deve ser maior ou igual a quantidade de execuções (${exs})`
  );
}

function nextExecution(application: any) {
  if (application && application.executions.length > 0) {
    return dayjs(
      application.executions[application.executions.length - 1].started_at
    ).add(application.cycle_interval_days, "day");
  }
  return dayjs(application.activity.start_date);
}
</script>
