<template>
  <v-card title="Tipos de Propriedade">
    <template v-slot:prepend>
      <v-avatar>
        <v-icon color="primary" icon="mdi-home-switch-outline"></v-icon>
      </v-avatar>
    </template>
    <v-card-text>
      <CRUDTable
        v-model:create-form-data="createFormData"
        v-model:edit-form-data="editFormData"
        v-model:delete-item="deleteItem"
        :url-base="urlBase"
        :messages="crudMsgs"
        :create-config="createConfig"
        :edit-config="editConfig"
        :delete-config="deleteConfig"
        :headers="tableHeaders"
      >
        <template v-slot:createForm>
          <v-text-field
            label="Nome"
            name="name"
            :error-messages="nameError"
            v-model="createFormData.name"
            validate-on="blur"
            :rules="[required]"
            autofocus
            ref="nameInput"
          ></v-text-field>
        </template>

        <template v-slot:editForm>
          <v-text-field
            label="Nome"
            name="name"
            :error-messages="nameError"
            v-model="editFormData.name"
            validate-on="blur"
            :rules="[required]"
            autofocus
            ref="nameInput"
          ></v-text-field>
        </template>

        <template v-slot:deleteForm>
          <p>
            <b>{{ deleteItem.id }} - {{ deleteItem.name }}</b>
          </p>
        </template>
      </CRUDTable>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import CRUDTable from "@/components/CRUDTable.vue";
import { required } from "@/validators";
import { watchOnce } from "@vueuse/core";
import { AxiosError } from "axios";
import { reactive, ref } from "vue";

const createFormDataDefault = {
  name: "",
};
const editFormDataDefault = {
  name: "",
};
const createFormData = reactive({ ...createFormDataDefault });
const editFormData = reactive({ ...editFormDataDefault });
const urlBase = "/admin/property-types";
const crudMsgs = {
  unexpectedError:
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.",
  createSuccess: "Tipo de Propriedade criado com sucesso!",
  editSuccess: "Tipo de Propriedade editado com sucesso!",
};
const createConfig = {
  btnText: "Novo Tipo de Propriedade",
  btnIcon: "mdi-plus",
  formTitle: "Novo Tipo de Propriedade",
  formIcon: "mdi-flask",
  submitText: "Salvar",
  formDataDefault: createFormDataDefault,
  handleCreateError: checkExistingName,
};
const editConfig = {
  btnText: "Editar Tipo de Propriedade",
  formTitle: "Editando Tipo de Propriedade",
  formIcon: "mdi-flask",
  submitText: "Salvar",
  formDataDefault: editFormDataDefault,
  handleEditError: checkExistingName,
};
const deleteConfig = {
  formTitle: "Excluindo Tipo de Propriedade",
  btnText: "Excluir",
};
const tableHeaders = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Nome",
    key: "name",
  },
  {
    title: "Ações",
    key: "actions",
    sortable: false,
    align: "end",
  },
];

const deleteItem = ref();

const existingNameMsg = "Tipo de Propriedade com este nome já existe";
const nameError = ref("");
const nameInput = ref();

function checkExistingName(err: AxiosError<any, any>) {
  if (err.response?.status === 409) {
    nameError.value = existingNameMsg;
    nameInput.value.focus();
    watchOnce(
      () => createFormData.name,
      () => (nameError.value = "")
    );
    watchOnce(
      () => editFormData.name,
      () => (nameError.value = "")
    );
    return true;
  }
  return false;
}
</script>
