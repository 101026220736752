import { RouteRecordRaw } from "vue-router";
import FieldsMapView from "@/modules/fields/mapview/FieldsMapView.vue";
import LoginView from "@/modules/users/LoginView.vue";
import NavbarView from "@/components/NavbarView.vue";
import UsersView from "@/modules/users/UsersView.vue";
import AdminView from "@/modules/admin/AdminView.vue";
import AdminOrganizationsView from "@/modules/admin/AdminOrganizationsView.vue";
import AdminUsersView from "@/modules/admin/AdminUsersView.vue";
import AdminHomeView from "@/modules/admin/AdminHomeView.vue";
import EquipmentsView from "@/modules/admin/application/EquipmentsView.vue";
import ProductsView from "@/modules/admin/application/ProductsView.vue";
import AdminHierarchiesView from "@/modules/admin/AdminHierarchiesView.vue";
import { useUsersStore } from "@/modules/users/store";
import ActivitiesView from "@/modules/activities/ActivitiesView.vue";
import AdminStationsView from "@/modules/admin/AdminStationsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "home",
    component: NavbarView,
    redirect: "map",
    children: [
      {
        path: "",
        name: "map",
        component: FieldsMapView,
        meta: {
          title: "Mapa | Dengue",
        },
      },
      {
        path: "usuarios",
        name: "users",
        component: UsersView,
        meta: {
          title: "Usuários | Dengue",
        },
      },
      {
        path: "atividades",
        name: "activities",
        component: ActivitiesView,
        meta: {
          title: "Atividades | Dengue",
        },
      },
      {
        path: "admin",
        name: "admin",
        component: AdminView,
        beforeEnter: (to, from) => {
          const userStore = useUsersStore();
          if (userStore.userMe?.is_admin) return true;
          return from;
        },
        meta: {
          title: "Admin | Dengue",
        },
        children: [
          {
            path: "",
            name: "admin.home",
            component: AdminHomeView,
            meta: {
              title: "Admin | Dengue",
            },
          },
          {
            path: "organizacoes",
            name: "admin.organizations",
            component: AdminOrganizationsView,
            meta: {
              title: "Organizações | Admin | Dengue",
            },
          },
          {
            path: "hierarquias",
            name: "admin.hierarchies",
            component: AdminHierarchiesView,
            meta: {
              title: "Hierarquias | Admin | Dengue",
            },
          },
          {
            path: "usuarios",
            name: "admin.users",
            component: AdminUsersView,
            meta: {
              title: "Usuários | Admin | Dengue",
            },
          },
          {
            path: "products",
            name: "admin.products",
            component: ProductsView,
            meta: {
              title: "Produtos | Admin | Dengue",
            },
          },
          {
            path: "equipments",
            name: "admin.equipments",
            component: EquipmentsView,
            meta: {
              title: "Equipamentos | Admin | Dengue",
            },
          },
          {
            path: "weather-stations",
            name: "admin.stations",
            component: AdminStationsView,
            meta: {
              title: "Estações | Admin | Dengue",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Dengue - Login",
    },
    props: (route) => ({ ...route.query, ...route.params }),
  },
];

export default routes;
