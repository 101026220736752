<template>
  <v-container fuild class="bg-white">
    <CRUDTable
      v-model:create-form-data="createFormData"
      v-model:edit-form-data="editFormData"
      v-model:form-error="formError"
      :url-base="urlBase"
      :messages="crudMsgs"
      :create-config="createConfig"
      :edit-config="editConfig"
      :headers="tableHeaders"
    >
      <template v-slot:[`item.web_access`]="{ item }">
        <v-icon
          :icon="item.web_access ? 'mdi-check' : 'mdi-cancel'"
          :color="item.web_access ? 'success' : 'error'"
        ></v-icon>
      </template>
      <template v-slot:[`item.app_access`]="{ item }">
        <v-icon
          :icon="item.app_access ? 'mdi-check' : 'mdi-cancel'"
          :color="item.app_access ? 'success' : 'error'"
        ></v-icon>
      </template>
      <template v-slot:[`item.users_permission`]="{ item }">
        <v-icon
          :icon="item.users_permission ? 'mdi-check' : 'mdi-cancel'"
          :color="item.users_permission ? 'success' : 'error'"
        ></v-icon>
      </template>
      <template v-slot:[`item.create_permission`]="{ item }">
        <v-icon
          :icon="item.create_permission ? 'mdi-check' : 'mdi-cancel'"
          :color="item.create_permission ? 'success' : 'error'"
        ></v-icon>
      </template>
      <template v-slot:[`item.delete_permission`]="{ item }">
        <v-icon
          :icon="item.delete_permission ? 'mdi-check' : 'mdi-cancel'"
          :color="item.delete_permission ? 'success' : 'error'"
        ></v-icon>
      </template>
      <template v-slot:[`item.application_feature_app`]="{ item }">
        <v-icon
          :icon="item.application_feature_app ? 'mdi-check' : 'mdi-cancel'"
          :color="item.application_feature_app ? 'success' : 'error'"
        ></v-icon>
      </template>
      <template v-slot:[`item.placevisit_feature_app`]="{ item }">
        <v-icon
          :icon="item.placevisit_feature_app ? 'mdi-check' : 'mdi-cancel'"
          :color="item.placevisit_feature_app ? 'success' : 'error'"
        ></v-icon>
      </template>
      <template v-slot:[`item.ovitrap_feature_app`]="{ item }">
        <v-icon
          :icon="item.ovitrap_feature_app ? 'mdi-check' : 'mdi-cancel'"
          :color="item.ovitrap_feature_app ? 'success' : 'error'"
        ></v-icon>
      </template>
      <template v-slot:createForm>
        <v-text-field
          label="Nome"
          name="name"
          v-model="createFormData.name"
          validate-on="blur"
          :rules="[required]"
          autofocus
          ref="nameInput"
        ></v-text-field>
        <v-text-field
          label="Nível"
          name="level"
          v-model="createFormData.level"
          validate-on="blur"
          :rules="[required]"
          type="number"
        ></v-text-field>
        <span class="text-h6">Acesso</span>
        <div class="d-flex justify-space-around">
          <v-checkbox
            label="Acesso Web"
            name="web_access"
            v-model="createFormData.web_access"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Acesso App"
            name="app_access"
            v-model="createFormData.app_access"
            hide-details
            single-line
          ></v-checkbox>
        </div>
        <span class="text-h6">Permissões</span>
        <div class="d-flex justify-space-around">
          <v-checkbox
            label="Usuários"
            name="users_permission"
            v-model="createFormData.users_permission"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Criação"
            name="create_permission"
            v-model="createFormData.create_permission"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Exclusão"
            name="delete_permission"
            v-model="createFormData.delete_permission"
            hide-details
            single-line
          ></v-checkbox>
        </div>
        <span class="text-h6">Features App</span>
        <div class="d-flex justify-space-around">
          <v-checkbox
            label="Aplicações"
            name="application_feature_app"
            v-model="createFormData.application_feature_app"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Vistorias"
            name="placevisit_feature_app"
            v-model="createFormData.placevisit_feature_app"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Armadilhas"
            name="ovitrap_feature_app"
            v-model="createFormData.ovitrap_feature_app"
            hide-details
            single-line
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:editForm>
        <v-text-field
          label="Nome"
          name="name"
          v-model="editFormData.name"
          validate-on="blur"
          :rules="[required]"
          autofocus
          ref="nameInput"
        ></v-text-field>
        <v-text-field
          label="Nível"
          name="level"
          v-model="editFormData.level"
          validate-on="blur"
          :rules="[required]"
          type="number"
        ></v-text-field>
        <span class="text-h6">Acesso</span>
        <div class="d-flex justify-space-around">
          <v-checkbox
            label="Acesso Web"
            name="web_access"
            v-model="editFormData.web_access"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Acesso App"
            name="app_access"
            v-model="editFormData.app_access"
            hide-details
            single-line
          ></v-checkbox>
        </div>
        <span class="text-h6">Permissões</span>
        <div class="d-flex justify-space-around">
          <v-checkbox
            label="Usuários"
            name="users_permission"
            v-model="editFormData.users_permission"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Criação"
            name="create_permission"
            v-model="editFormData.create_permission"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Exclusão"
            name="delete_permission"
            v-model="editFormData.delete_permission"
            hide-details
            single-line
          ></v-checkbox>
        </div>
        <span class="text-h6">Features App</span>
        <div class="d-flex justify-space-around">
          <v-checkbox
            label="Aplicações"
            name="application_feature_app"
            v-model="editFormData.application_feature_app"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Vistorias"
            name="placevisit_feature_app"
            v-model="editFormData.placevisit_feature_app"
            hide-details
            single-line
          ></v-checkbox>
          <v-checkbox
            label="Armadilhas"
            name="ovitrap_feature_app"
            v-model="editFormData.ovitrap_feature_app"
            hide-details
            single-line
          ></v-checkbox>
        </div>
      </template>
    </CRUDTable>
  </v-container>
</template>

<script setup lang="ts">
import CRUDTable from "@/components/CRUDTable.vue";
import { useLoadOrganizations } from "@/modules/admin/composables";
import { required } from "@/validators";
import { watchOnce } from "@vueuse/core";
import { AxiosError } from "axios";
import { reactive, ref } from "vue";

const defaultFormData = {
  name: "",
  level: "",
  users_permission: true,
  create_permission: true,
  delete_permission: true,
  web_access: true,
  app_access: true,
  application_feature_app: true,
  placevisit_feature_app: false,
  ovitrap_feature_app: false,
};
const createFormData = reactive({ ...defaultFormData });
const editFormData = reactive({ ...defaultFormData });

const urlBase = "/admin/hierarchies";
const crudMsgs = {
  unexpectedError:
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.",
  createSuccess: "Hierarquia criada com sucesso!",
  editSuccess: "Hierarquia editada com sucesso!",
};
const createConfig = {
  btnText: "Nova Hierarquia",
  btnIcon: "mdi-plus",
  formTitle: "Nova Hierarquia",
  formIcon: "mdi-shape",
  submitText: "Salvar",
  formDataDefault: defaultFormData,
  handleCreateError: checkExistingName,
};
const editConfig = {
  btnText: "Editar Hierarquia",
  formTitle: "Editando Hierarquia",
  formIcon: "mdi-engine",
  submitText: "Salvar",
  formDataDefault: defaultFormData,
  handleEditError: checkExistingName,
};
const tableHeaders = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Nome",
    key: "name",
  },
  {
    title: "Nível",
    key: "level",
  },
  {
    title: "Web",
    key: "web_access",
  },
  {
    title: "App",
    key: "app_access",
  },
  {
    title: "Usuários",
    key: "users_permission",
  },
  {
    title: "Criação",
    key: "create_permission",
  },
  {
    title: "Exclusão",
    key: "delete_permission",
  },
  {
    title: "Aplicações",
    key: "application_feature_app",
  },
  {
    title: "Vistorias",
    key: "placevisit_feature_app",
  },
  {
    title: "Armadilhas",
    key: "ovitrap_feature_app",
  },
  {
    title: "Ações",
    key: "actions",
    sortable: false,
    align: "end",
  },
];

const formError = ref("");
const nameInput = ref();
const duplicateMsg = (name: string, level: string) =>
  `Hierarquia '${name} nível ${level}' já existe.`;

function checkExistingName(err: AxiosError<any, any>) {
  if (err.response?.status === 409) {
    formError.value = duplicateMsg(
      createFormData.name || editFormData.name,
      createFormData.level || editFormData.level
    );
    nameInput.value.focus();
    watchOnce(
      [() => createFormData.name, () => createFormData.level],
      () => (formError.value = "")
    );
    return true;
  }
  return false;
}
</script>
