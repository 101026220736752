<template>
  <div class="mb-2">
    <div class="d-flex align-center justify-space-between">
      <span class="text-h5">Casos</span>
      <v-btn
        v-if="userStore.canCreate"
        prepend-icon="mdi-plus"
        variant="tonal"
        color="primary"
        text="Novo"
        @click="initCreate"
      ></v-btn>
    </div>

    <template v-if="casesRecord">
      <div
        v-if="loadingCasesRecord"
        class="text-center text-caption text-medium-emphasis my-2"
      >
        Carregando...
        <v-progress-linear
          indeterminate
          color="primary"
          class="my-2"
        ></v-progress-linear>
      </div>
      <div v-if="!loadingCasesRecord" class="px-2">
        <p>
          Último registro: {{ dayjs(casesRecord.date).format("DD/MM/YYYY") }}
        </p>
        <p>Suspeitos: {{ casesRecord.suspected ?? "--" }}</p>
        <p>Confirmados: {{ casesRecord.confirmed ?? "--" }}</p>
        <p>Óbitos: {{ casesRecord.deaths ?? "--" }}</p>
      </div>
    </template>
    <div
      v-if="!casesRecord"
      class="text-center text-caption text-medium-emphasis my-2"
    >
      <p>Nenhum registro de casos</p>
    </div>
  </div>

  <FormDialog
    title="Novo Registro de Casos"
    titleIcon="mdi-file-document-plus"
    submitText="Salvar"
    maxWidth="700"
    :open="openCreateForm"
    :form-error="formError"
    :submit="submitCreate"
    @close="closeCreate"
  >
    <v-text-field
      type="date"
      label="Data do registro"
      name="date"
      v-model="createFormData.date"
      validate-on="input"
      :rules="[required]"
      autofocus
    ></v-text-field>
    <div class="d-flex">
      <v-text-field
        type="number"
        label="Suspeitos"
        name="suspected"
        v-model="createFormData.suspected"
        validate-on="input"
        class="mr-2"
        :rules="[minValue(0)]"
      ></v-text-field>
      <v-text-field
        type="number"
        label="Confirmados"
        name="confirmed"
        v-model="createFormData.confirmed"
        validate-on="input"
        class="mr-2"
        :rules="[minValue(0)]"
      ></v-text-field>
      <v-text-field
        type="number"
        label="Óbitos"
        name="deaths"
        v-model="createFormData.deaths"
        validate-on="input"
        :rules="[minValue(0)]"
      ></v-text-field>
    </div>
  </FormDialog>
</template>

<script setup lang="ts">
import FormDialog from "@/components/FormDialog.vue";
import { useUsersStore } from "@/modules/users/store";
import { useSnackbar } from "@/store";
import { minValue, required } from "@/validators";
import { watchOnce } from "@vueuse/core";
import axios from "axios";
import dayjs from "dayjs";
import { onBeforeMount, ref, reactive } from "vue";

const props = defineProps<{
  fieldId: number;
}>();

const userStore = useUsersStore();
const { showSnackbar } = useSnackbar();

const casesRecord = ref<any>();
const loadingCasesRecord = ref(false);
onBeforeMount(() => {
  getLastestCasesRecord();
});

async function getLastestCasesRecord() {
  loadingCasesRecord.value = true;
  try {
    const { data } = await axios.get(
      `/fields/${props.fieldId}/cases-records/latest`
    );
    casesRecord.value = data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      console.log(err.response?.data);
    }
  } finally {
    loadingCasesRecord.value = false;
  }
}

const messages = {
  createSuccess: "Registro de casos criado com sucesso",
  unexpectedError: "Ocorreu um erro inesperado. Tente novamente mais tarde.",
};

const formDataDefaults = {
  date: undefined,
  suspected: undefined,
  confirmed: undefined,
  deaths: undefined,
};
const createFormData = reactive({ ...formDataDefaults });
const openCreateForm = ref(false);
const formError = ref("");

function initCreate() {
  openCreateForm.value = true;
}

function closeCreate() {
  Object.assign(createFormData, formDataDefaults);
  openCreateForm.value = false;
  formError.value = "";
}

async function submitCreate() {
  if (
    createFormData.suspected ||
    createFormData.confirmed ||
    createFormData.deaths
  ) {
    try {
      await axios.post(
        `/fields/${props.fieldId}/cases-records`,
        createFormData
      );
      closeCreate();
      showSnackbar(messages.createSuccess, "success");
      getLastestCasesRecord();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data);
      }
      formError.value = messages.unexpectedError;
      watchOnce(
        () => createFormData,
        () => (formError.value = "")
      );
    }
    return;
  }

  formError.value = "Preencha pelo menos um dos registros de casos";
  watchOnce(
    () => createFormData,
    () => (formError.value = "")
  );
}
</script>
