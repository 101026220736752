<template>
  <div class="d-flex h-100">
    <div id="sidebar">
      <v-list class="h-100">
        <v-list-item
          prepend-icon="mdi-home"
          title="Principal"
          value="home"
          :active="$route.name === 'admin.home'"
          :to="{ name: 'admin.home' }"
          active-color="primary"
        >
        </v-list-item>
        <v-list-item
          prepend-icon="mdi-briefcase"
          title="Organizações"
          value="organizations"
          :active="$route.name === 'admin.organizations'"
          :to="{ name: 'admin.organizations' }"
          active-color="primary"
        >
        </v-list-item>
        <v-list-item
          prepend-icon="mdi-badge-account-horizontal"
          title="Hierarquias"
          value="hierarchies"
          :active="$route.name === 'admin.hierarchies'"
          :to="{ name: 'admin.hierarchies' }"
          active-color="primary"
        >
        </v-list-item>
        <v-list-item
          prepend-icon="mdi-account-group"
          title="Usuários"
          value="users"
          :active="$route.name === 'admin.users'"
          :to="{ name: 'admin.users' }"
          active-color="primary"
        >
        </v-list-item>
        <v-list-group
          value="application"
          prepend-icon="mdi-fire-truck"
          title="Aplicação"
        >
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props"></v-list-item>
          </template>
          <v-list-item
            prepend-icon="mdi-flask"
            title="Produtos"
            value="products"
            :active="$route.name === 'admin.products'"
            :to="{ name: 'admin.products' }"
            active-color="primary"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-engine"
            title="Equipamentos"
            value="equipments"
            :active="$route.name === 'admin.equipments'"
            :to="{ name: 'admin.equipments' }"
            active-color="primary"
          ></v-list-item>
        </v-list-group>
        <v-list-item
          prepend-icon="mdi-thermometer"
          title="Estações"
          value="stations"
          :active="$route.name === 'admin.stations'"
          :to="{ name: 'admin.stations' }"
          active-color="primary"
        >
        </v-list-item>
      </v-list>
    </div>
    <div class="flex-1-1">
      <router-view></router-view>
    </div>
  </div>
</template>

<style scoped lang="scss">
#sidebar {
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.12);
  border-width: thin;
  width: 256px;
}

.v-list-group__items .v-list-item {
  padding-inline-start: 32px !important;
}
</style>
