<template>
  <div class="px-4 py-2 d-flex justify-space-between">
    <v-combobox
      append-inner-icon="mdi-magnify"
      label="Pesquisar"
      name="search"
      density="compact"
      v-model="search"
      hide-details
      single-line
      multiple
      chips
      autocomplete="off"
      style="max-width: 400px"
    ></v-combobox>
    <v-btn
      v-if="createConfig"
      :text="createConfig.btnText"
      variant="tonal"
      color="primary"
      :prepend-icon="createConfig.btnIcon"
      @click="initCreate"
    ></v-btn>
  </div>
  <v-data-table
    class="crud-table"
    :items="items"
    item-value="id"
    :headers="headers"
    :loading="loading"
    :search="searchQuery"
    :custom-filter="customFilter"
  >
    <template v-for="slot in Object.keys(slots)" v-slot:[slot]="{ item }">
      <slot :name="slot" :item="item"></slot>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        v-if="editConfig"
        icon="mdi-pencil"
        :data-tippy-content="editConfig!.btnText"
        color="primary"
        @click="initEdit(item)"
      >
      </v-icon>
      <v-icon
        v-if="deleteConfig"
        icon="mdi-delete"
        class="ml-2"
        :data-tippy-content="deleteConfig!.btnText"
        color="error"
        @click="initDelete(item)"
      >
      </v-icon>
    </template>
  </v-data-table>

  <FormDialog
    v-if="createConfig"
    :title="createConfig.formTitle"
    :titleIcon="createConfig.formIcon"
    :submitText="createConfig.submitText"
    :open="openCreateForm"
    :form-error="formError"
    :submit="submitCreate"
    @close="closeCreate"
  >
    <slot name="createForm"></slot>
  </FormDialog>

  <FormDialog
    v-if="editConfig"
    :title="editConfig.formTitle"
    :titleIcon="editConfig.formIcon"
    :submitText="editConfig.submitText"
    :open="openEditForm"
    :form-error="formError"
    :submit="submitEdit"
    @close="closeEdit"
  >
    <slot name="editForm"></slot>
  </FormDialog>

  <DeleteDialog
    v-if="deleteConfig"
    v-model:open="deleteDialog"
    :title="deleteConfig.formTitle"
    :text="deleteConfig.text ?? 'Deseja excluir o registro selecionado?'"
    @delete="deleteItem"
  ></DeleteDialog>
</template>

<script setup lang="ts">
import { useSnackbar } from "@/store";
import { watchOnce } from "@vueuse/core";
import axios, { AxiosError } from "axios";
import tippy from "tippy.js";
import { computed, onBeforeMount, ref, useSlots } from "vue";
import FormDialog from "./FormDialog.vue";
import DeleteDialog from "./DeleteDialog.vue";

export interface Messages {
  unexpectedError: string;
  createSuccess: string;
  editSuccess: string;
  deleteSuccess?: string;
}

export interface CreateConfig {
  btnText: string;
  btnIcon: string;
  formTitle: string;
  formIcon: string;
  submitText: string;
  formDataDefault: any;
  handleCreateError?: (err: AxiosError<any, any>) => boolean;
}

export interface EditConfig {
  btnText: string;
  formTitle: string;
  formIcon: string;
  submitText: string;
  formDataDefault: any;
  handleEditError?: (err: AxiosError<any, any>) => boolean;
}

export interface DeleteConfig {
  formTitle: string;
  btnText: string;
  text?: string;
}

interface Props {
  messages: Messages;
  urlBase: string;
  headers: any[];
  createConfig?: CreateConfig;
  editConfig?: EditConfig;
  deleteConfig?: DeleteConfig;
  itemsProcess?: (items: any[]) => any[];
  createProcess?: (data: any) => any;
  editProcess?: (data: any) => any;
}
const props = withDefaults(defineProps<Props>(), {});

const defaultMessages = {
  unexpectedError: "Erro inesperado",
  createSuccess: "Criado com sucesso",
  editSuccess: "Editado com sucesso",
  deleteSuccess: "Excluído com sucesso",
};

// eslint-disable-next-line
const formError = defineModel("formError", {
  required: false,
  type: String,
  default: "",
});
// eslint-disable-next-line
const createFormData = defineModel("createFormData", {
  required: true,
  type: Object,
});
// eslint-disable-next-line
const editFormData = defineModel("editFormData", {
  required: true,
  type: Object,
});

const { showSnackbar } = useSnackbar();

const emit = defineEmits<{
  (e: "createOpened"): void;
  (e: "editOpened", item: any): void;
  (e: "deleteOpened", item: any): void;
}>();

const slots = useSlots();
const items = ref<any[]>([]);
const search = ref<string[]>([]);
const loading = ref(false);
const searchQuery = computed(() => {
  return search.value.join(",");
});

onBeforeMount(() => {
  getItems();
});

function customFilter(
  value: string | number,
  query: string,
  item?: any
): boolean | number | [number, number] | [number, number][] {
  if (typeof value === "number") value = value.toString();

  const queries = query
    .trim()
    .split(",")
    .filter((q) => q)
    .map((q) => q.trim().toLocaleLowerCase());

  return (
    value != null &&
    query != null &&
    typeof value === "string" &&
    queries.every((q) =>
      value
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(q)
    )
  );
}

async function getItems() {
  try {
    loading.value = true;
    const res = await axios.get(props.urlBase);
    items.value = props.itemsProcess ? props.itemsProcess(res.data) : res.data;
    if (res.data.length != items.value.length) {
      throw new Error(
        "CRUDTable: 'itemsProcess' returned different number of items from response"
      );
    }
  } catch (err) {
    showSnackbar(props.messages.unexpectedError, "error");
    console.error(err);
  } finally {
    loading.value = false;
    setTimeout(() => {
      tippy("[data-tippy-content]", { arrow: false });
    });
  }
}

const openCreateForm = ref(false);

function initCreate() {
  openCreateForm.value = true;
  emit("createOpened");
}

async function submitCreate() {
  formError.value = "";
  try {
    await axios.post(props.urlBase, createFormData.value);
    closeCreate();
    showSnackbar(props.messages.createSuccess, "success");
    getItems();
  } catch (err) {
    handleSubmitError(err, props.createConfig?.handleCreateError);
  }
}

function closeCreate() {
  formError.value = "";
  openCreateForm.value = false;
  resetCreateForm();
}

function resetCreateForm() {
  Object.assign(createFormData.value, props.createConfig?.formDataDefault);
}

const openEditForm = ref(false);
const editId = ref();

function initEdit(item: any) {
  for (const key in editFormData.value) {
    editFormData.value[key] = item[key];
  }
  openEditForm.value = true;
  editId.value = item.id;
  emit("editOpened", item);
}

async function submitEdit() {
  formError.value = "";
  try {
    await axios.patch(`${props.urlBase}/${editId.value}`, editFormData.value);
    closeEdit();
    showSnackbar(props.messages.editSuccess, "success");
    getItems();
  } catch (err) {
    handleSubmitError(err, props.editConfig?.handleEditError);
  }
}

function handleSubmitError(
  err: any,
  handler?: (err: AxiosError<any, any>) => boolean
) {
  if (axios.isAxiosError(err)) {
    const errorHandled = handler && handler(err);
    if (errorHandled) return;
  }
  formError.value = props.messages.unexpectedError;
  console.error(err);
  watchOnce(
    () => editFormData,
    () => (formError.value = "")
  );
}

function closeEdit() {
  formError.value = "";
  openEditForm.value = false;
  resetEditForm();
}

function resetEditForm() {
  editId.value = undefined;
  Object.assign(editFormData.value, props.editConfig?.formDataDefault);
}

const deleteDialog = ref(false);
// eslint-disable-next-line
const itemToDelete = defineModel("deleteItem", {
  required: false,
  type: Object,
});

function initDelete(item: any) {
  itemToDelete.value = item;
  deleteDialog.value = true;
  emit("deleteOpened", item);
}

async function deleteItem() {
  formError.value = "";
  try {
    await axios.delete(`${props.urlBase}/${itemToDelete.value.id}`);
    closeDelete();
    showSnackbar(
      props.messages.deleteSuccess
        ? props.messages.deleteSuccess
        : defaultMessages.deleteSuccess,
      "success"
    );
    getItems();
  } catch (err) {
    handleSubmitError(err);
  }
}

function closeDelete() {
  deleteDialog.value = false;
}
</script>

<style scoped lang="scss">
.crud-table::v-deep tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
</style>
