import dayjs from "dayjs";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useUsersStore = defineStore("users", () => {
  const userMe = ref<{
    id: number;
    email: string;
    full_name: string;
    is_active: boolean;
    is_admin: boolean;
    hierarchy: {
      id: number;
      name: string;
      level: number;
      users_permission: boolean;
      create_permission: boolean;
      delete_permission: boolean;
      web_access: boolean;
    };
    session: {
      expires_at: string;
    };
  }>();

  const canUsers = computed(() => {
    return userMe.value?.is_admin || userMe.value?.hierarchy.users_permission;
  });

  const canCreate = computed(() => {
    return userMe.value?.is_admin || userMe.value?.hierarchy.create_permission;
  });

  const canDelete = computed(() => {
    return userMe.value?.is_admin || userMe.value?.hierarchy.delete_permission;
  });

  const sessionExpired = computed(() => {
    return dayjs(userMe.value?.session.expires_at).isBefore(dayjs());
  });

  function logout() {
    userMe.value = undefined;
  }

  return {
    userMe,
    canUsers,
    canCreate,
    canDelete,
    sessionExpired,
    logout,
  };
});
