<template>
  <v-card title="Produtos">
    <template v-slot:prepend>
      <v-avatar>
        <v-icon color="primary" icon="mdi-flask"></v-icon>
      </v-avatar>
    </template>
    <v-card-text>
      <CRUDTable
        v-model:create-form-data="createFormData"
        v-model:edit-form-data="editFormData"
        :url-base="urlBase"
        :messages="crudMsgs"
        :create-config="createConfig"
        :edit-config="editConfig"
        :headers="tableHeaders"
      >
        <template v-slot:[`item.is_active`]="{ item }">
          <v-icon
            :icon="item.is_active ? 'mdi-check' : 'mdi-cancel'"
            :color="item.is_active ? 'success' : 'error'"
          ></v-icon>
        </template>

        <template v-slot:createForm>
          <v-text-field
            label="Nome"
            name="name"
            :error-messages="nameError"
            v-model="createFormData.name"
            validate-on="blur"
            :rules="[required]"
            autofocus
            ref="nameInput"
          ></v-text-field>
          <div class="d-flex">
            <v-text-field
              type="number"
              label="Nº de Ciclos"
              name="number_of_cycles"
              v-model="createFormData.number_of_cycles"
              validate-on="blur"
              :rules="[required]"
            ></v-text-field>
            <v-text-field
              type="number"
              class="ml-2"
              label="Intervalo (dias)"
              name="cycle_interval_days"
              v-model="createFormData.cycle_interval_days"
              validate-on="blur"
              :rules="[required]"
            ></v-text-field>
          </div>
        </template>

        <template v-slot:editForm>
          <v-text-field
            label="Nome"
            name="name"
            :error-messages="nameError"
            v-model="editFormData.name"
            validate-on="blur"
            :rules="[required]"
            autofocus
            ref="nameInput"
          ></v-text-field>
          <div class="d-flex">
            <v-text-field
              type="number"
              label="Nº de Ciclos"
              name="number_of_cycles"
              v-model="editFormData.number_of_cycles"
              validate-on="blur"
              :rules="[required]"
            ></v-text-field>
            <v-text-field
              type="number"
              class="ml-2"
              label="Intervalo (dias)"
              name="cycle_interval_days"
              v-model="editFormData.cycle_interval_days"
              validate-on="blur"
              :rules="[required]"
            ></v-text-field>
          </div>
        </template>
      </CRUDTable>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import CRUDTable from "@/components/CRUDTable.vue";
import { required } from "@/validators";
import { watchOnce } from "@vueuse/core";
import { AxiosError } from "axios";
import { reactive, ref } from "vue";

const createFormDataDefault = {
  name: "",
  number_of_cycles: 0,
  cycle_interval_days: 0,
};
const editFormDataDefault = {
  name: "",
  number_of_cycles: 0,
  cycle_interval_days: 0,
};
const createFormData = reactive({ ...createFormDataDefault });
const editFormData = reactive({ ...editFormDataDefault });
const urlBase = "/admin/products";
const crudMsgs = {
  unexpectedError:
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.",
  createSuccess: "Produto criado com sucesso!",
  editSuccess: "Produto editado com sucesso!",
};
const createConfig = {
  btnText: "Novo Produto",
  btnIcon: "mdi-plus",
  formTitle: "Novo Produto",
  formIcon: "mdi-flask",
  submitText: "Salvar",
  formDataDefault: createFormDataDefault,
  handleCreateError: checkExistingName,
};
const editConfig = {
  btnText: "Editar Produto",
  formTitle: "Editando Produto",
  formIcon: "mdi-flask",
  submitText: "Salvar",
  formDataDefault: editFormDataDefault,
  handleEditError: checkExistingName,
};
const tableHeaders = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Nome",
    key: "name",
  },
  {
    title: "Nº de Ciclos",
    key: "number_of_cycles",
  },
  {
    title: "Intervalo (dias)",
    key: "cycle_interval_days",
  },
  {
    title: "Ações",
    key: "actions",
    sortable: false,
    align: "end",
  },
];

const existingNameMsg = "Produto com este nome já existe";
const nameError = ref("");
const nameInput = ref();

function checkExistingName(err: AxiosError<any, any>) {
  if (err.response?.status === 409) {
    nameError.value = existingNameMsg;
    nameInput.value.focus();
    watchOnce(
      () => createFormData.name,
      () => (nameError.value = "")
    );
    return true;
  }
  return false;
}
</script>
