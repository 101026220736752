import { createApp } from "vue";
import { createPinia } from "pinia";
import resetStore from "./plugins/reset-store";
import App from "./App.vue";
import router from "./router";
import { vMaska } from "maska";

// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import vuetifyPlugin from "@/plugins/vuetify";

// Mapbox GL
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

// Tippy JS
import "tippy.js/dist/tippy.css";

// Directives
import simplemaskmoney from "./directives/simplemaskmoney";

// dayjs
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.locale("pt-BR");
dayjs.updateLocale("pt-BR", {
  relativeTime: {
    future: "em %s",
    past: "%s atrás",
    s: "poucos segundos",
    m: "um minuto",
    mm: "%d minutos",
    h: "uma hora",
    hh: "%d horas",
    d: "um dia",
    dd: "%d dias",
    M: "um mês",
    MM: "%d meses",
    y: "um ano",
    yy: "%d anos",
  },
});

const pinia = createPinia();
pinia.use(resetStore);
const app = createApp(App);

// Plugins
app.use(pinia);
app.use(router);
app.use(vuetifyPlugin);

// Directives
app.directive("simple-mask-money", simplemaskmoney);
app.directive("maska", vMaska);

app.mount("#app");
