<template>
  <div class="d-flex w-100">
    <div class="w-50">
      <div class="px-4 py-2 d-flex justify-space-between align-end">
        <span class="text-h5">Atividades</span>
        <v-combobox
          append-inner-icon="mdi-magnify"
          label="Pesquisar"
          name="search"
          density="compact"
          v-model="search"
          hide-details
          single-line
          multiple
          chips
          style="max-width: 400px"
          clearable
          autocomplete="off"
        ></v-combobox>
      </div>
      <div
        v-if="loading"
        class="text-center text-caption text-medium-emphasis my-2 mx-6"
      >
        Carregando...
        <v-progress-linear
          indeterminate
          color="primary"
          class="my-2"
        ></v-progress-linear>
      </div>
      <v-data-iterator
        :items="items"
        :page="page"
        :items-per-page="5"
        :search="searchQuery"
        :custom-filter="customFilter"
      >
        <template v-slot:default="{ items }">
          <v-sheet
            v-for="(item, i) in items"
            :key="i"
            elevation="1"
            rounded
            :class="selectedItem == item ? 'selected-item' : ''"
            class="ma-2 activity-item"
            :style="`border-left-color: ${classifyActivity(
              item.raw
            )}; border-left-style: solid; border-left-width: 5px;`"
            @click="selectItem(item)"
          >
            <v-row no-gutters>
              <v-col cols="4">
                <div class="pa-4">
                  <p
                    class="text-h6 d-inline-block text-truncate"
                    style="max-width: 100%"
                    :title="item.raw.field.name"
                  >
                    {{ item.raw.field.name }}
                  </p>
                  <p class="text-medium-emphasis">
                    {{ formatDecimal(item.raw.field.size_m2) }}
                    <small>m²</small>
                  </p>
                  <p class="">{{ item.raw.field.organization.name }}</p>
                </div>
              </v-col>
              <v-col cols="8">
                <v-card
                  title="Aplicação"
                  variant="flat"
                  class="flex-fill"
                  color="transparent"
                >
                  <template v-slot:subtitle>
                    <div>
                      {{ item.raw.application.executions.length }}/{{
                        item.raw.application.total_cycles
                      }}
                      -
                      <span
                        v-if="
                          !item.raw.finish_date &&
                          item.raw.application.executions.length == 0
                        "
                      >
                        {{
                          `Início em ${dayjs(item.raw.start_date).format(
                            "DD/MM/YYYY"
                          )} (${
                            dayjs(item.raw.start_date).isSame(dayjs(), "day")
                              ? "hoje"
                              : dayjs(item.raw.start_date).fromNow()
                          })`
                        }}
                      </span>
                      <span
                        v-if="
                          item.raw.application.executions.length > 0 &&
                          !item.raw.finish_date
                        "
                      >
                        {{
                          `Próxima em ${dayjs(nextExecution(item.raw)).format(
                            "DD/MM/YYYY"
                          )} (${
                            dayjs(nextExecution(item.raw)).isSame(
                              dayjs(),
                              "day"
                            )
                              ? "hoje"
                              : dayjs(nextExecution(item.raw)).fromNow()
                          })`
                        }}
                      </span>
                      <span v-if="item.raw.finish_date">
                        {{
                          `Finalizada em ${dayjs(item.raw.finish_date).format(
                            "DD/MM/YYYY"
                          )}`
                        }}
                      </span>
                    </div>
                  </template>
                  <template v-slot:prepend>
                    <v-progress-circular
                      :model-value="
                        (item.raw.application.executions.length /
                          item.raw.application.total_cycles) *
                        100
                      "
                      :size="36"
                      :width="18"
                      :bg-color="
                        item.raw.finish_date
                          ? 'green-lighten-5'
                          : 'grey-lighten-2'
                      "
                      :color="item.raw.finish_date ? 'green' : 'indigo'"
                    >
                    </v-progress-circular>
                  </template>
                  <template
                    v-if="
                      (userStore.canCreate && !item.raw.finish_date) ||
                      userStore.canDelete
                    "
                    v-slot:append
                  >
                    <v-btn
                      icon="mdi-dots-vertical"
                      variant="text"
                      size="small"
                      style="
                        position: absolute;
                        right: 0;
                        top: 0;
                        margin: 0.5rem;
                      "
                    >
                      <v-icon icon="mdi-dots-vertical"></v-icon>
                      <v-menu activator="parent">
                        <v-list>
                          <v-list-item
                            v-if="!item.raw.finish_date && userStore.canCreate"
                            prepend-icon="mdi-pencil"
                            @click="openEdit(item.raw)"
                          >
                            <v-list-item-title>Editar</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="!item.raw.finish_date && userStore.canCreate"
                            prepend-icon="mdi-check"
                            @click="initFinish(item.raw)"
                          >
                            <v-list-item-title>Finalizar</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="
                              item.raw.application.executions.length == 0 &&
                              userStore.canDelete
                            "
                            base-color="error"
                            prepend-icon="mdi-delete"
                            @click="openDeleteDialog(item.raw)"
                          >
                            <v-list-item-title>Excluir</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-btn>
                  </template>
                  <v-card-text>
                    <div class="d-flex">
                      <p class="mx-2">
                        <v-icon
                          class="text-medium-emphasis mr-1"
                          icon="mdi-flask"
                          style="vertical-align: sub"
                          size="large"
                          data-tippy-content="Produto"
                        ></v-icon>
                        {{ item.raw.application.product.name }}
                      </p>
                      <p class="mx-2">
                        <v-icon
                          class="text-medium-emphasis mr-1"
                          icon="mdi-engine"
                          style="vertical-align: sub"
                          size="large"
                          data-tippy-content="Equipamento"
                        ></v-icon>
                        {{ item.raw.application.equipment.name }}
                      </p>
                      <p class="mx-2">
                        <v-icon
                          class="text-medium-emphasis mr-1"
                          icon="mdi-sun-clock"
                          style="vertical-align: sub"
                          size="large"
                          data-tippy-content="Intervalo de Aplicação"
                        ></v-icon>
                        {{ item.raw.application.cycle_interval_days }} dias
                      </p>
                      <p class="mx-2">
                        <v-icon
                          class="text-medium-emphasis mr-1"
                          icon="mdi-spray"
                          style="vertical-align: sub"
                          size="large"
                          data-tippy-content="Taxa de Aplicação"
                        ></v-icon>
                        {{
                          SimpleMaskMoney.formatToCurrency(
                            item.raw.application.application_rate
                          )
                        }}
                        <span class="text-caption">{{
                          item.raw.application.application_unit
                        }}</span>
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </template>
        <template v-slot:footer="{ pageCount }">
          <div>
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
            ></v-pagination>
          </div>
        </template>
      </v-data-iterator>
    </div>
    <div class="w-50">
      <div class="px-4 py-4 d-flex justify-space-between align-end">
        <span class="text-h5">Detalhes</span>
      </div>
      <v-sheet rounded elevation="1" class="pa-4 mr-2">
        <div>
          <div v-if="!selectedItem" class="text-center">
            <span class="text-h5 text-medium-emphasis"
              >Selecione uma atividade para ver os detalhes</span
            >
          </div>
          <v-timeline
            v-if="selectedItem"
            align="start"
            density="compact"
            truncate-line="both"
            class="mt-2"
          >
            <v-timeline-item
              v-for="execution in selectedItem.raw.application.executions"
              :key="execution.id"
              dot-color="success"
              size="small"
            >
              <!-- <template v-slot:icon>
                <v-icon
                  icon="mdi-record"
                  :color="execution === selectedExecution ? 'white' : 'success'"
                  @click="selectExecution(execution)"
                ></v-icon>
              </template> -->
              <div>
                <div>
                  <strong class="text-body-1 font-weight-bold">{{
                    dayjs(execution.started_at).format("DD/MM/YYYY")
                  }}</strong>
                  ({{
                    dayjs(execution.started_at).isSame(dayjs(), "day")
                      ? "hoje"
                      : dayjs(execution.started_at).fromNow()
                  }})
                </div>
              </div>
              <div>
                <v-icon
                  class="text-medium-emphasis mr-1"
                  icon="mdi-account"
                  style="vertical-align: sub"
                  data-tippy-content="Executor"
                ></v-icon>
                {{ execution.executor.full_name }}
              </div>
              <div>
                <v-icon
                  class="text-medium-emphasis mr-1"
                  icon="mdi-timer-play-outline"
                  style="vertical-align: sub"
                  :data-tippy-content="
                    'Duração ' +
                    differenceInHoursMinutes(
                      execution.started_at,
                      execution.finished_at
                    )
                  "
                ></v-icon>
                {{ dayjs(execution.started_at).format("HH:mm") }}
                -
                {{ dayjs(execution.finished_at).format("HH:mm") }}
              </div>
              <div
                v-if="execution.weather_conditions"
                :data-tippy-content="
                  execution.weather_conditions_percentage != null
                    ? execution.weather_conditions_percentage +
                      '% em Condições ideais'
                    : 'Condições de aplicação'
                "
              >
                <v-icon
                  class="text-medium-emphasis mr-1"
                  icon="mdi-windsock"
                  style="vertical-align: sub"
                ></v-icon>
                Iniciado em {{ execution.weather_conditions }}
              </div>
              <div>
                <v-icon
                  class="text-medium-emphasis mr-1"
                  icon="mdi-speedometer"
                  style="vertical-align: sub"
                  data-tippy-content="Velocidade média"
                ></v-icon>
                {{ execution.average_speed.toFixed(1).replace(".", ",") }}
                km/h
              </div>
              <div
                v-if="execution.flowmeter_work"
                @click="showFlowmeterWork(execution)"
                class="cursor-pointer"
              >
                <v-icon
                  class="text-medium-emphasis mr-1"
                  icon="mdi-thermometer-water"
                  style="vertical-align: bottom"
                  data-tippy-content="Leituras do fluxômetro"
                ></v-icon>
                Fluxômetro
              </div>
            </v-timeline-item>
            <v-timeline-item
              v-for="d in futureExecutions(selectedItem.raw)"
              :key="d.format()"
              dot-color="white"
              size="x-small"
            >
              <div>
                <div>
                  <span class="font-weight-medium">{{
                    d.locale("pt-BR").format("DD/MM/YYYY")
                  }}</span>
                  ({{ d.isSame(dayjs(), "day") ? "hoje" : d.fromNow() }})
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-sheet>
    </div>
  </div>

  <EditActivityForm
    :open="openEditForm"
    :activity="editActivity"
    @edited="listItems()"
    @close="openEditForm = false"
  ></EditActivityForm>

  <DeleteDialog
    v-model:open="deleteDialog"
    title="Exclusão de Atividade"
    text="Deseja realmente excluir a atividade?"
    @delete="deleteActivity"
  ></DeleteDialog>

  <FormDialog
    title="Finalizar Atividade"
    titleIcon="mdi-check"
    submitText="Confirmar"
    :open="openFinishForm"
    :form-error="formError"
    :submit="submitFinish"
    @close="closeFinish"
  >
    <v-textarea
      label="Justificativa"
      name="start_date"
      v-model="finishFormData.observation"
      validate-on="input"
      :rules="[required]"
      autofocus
    ></v-textarea>
  </FormDialog>

  <v-dialog
    v-model="flowmeterDialog"
    max-width="600"
    transition="dialog-transition"
    :persistent="true"
    retain-focus
    @keyup.esc="flowmeterDialog = false"
  >
    <v-card>
      <v-card-title>Leituras do fluxômetro</v-card-title>
      <v-btn
        icon="mdi-close"
        tabindex="-1"
        variant="text"
        @click="flowmeterDialog = false"
        style="position: absolute; right: 0; top: 0; margin: 0.5rem"
      >
      </v-btn>
      <v-card-text>
        <v-table density="compact" v-if="flowmeterWork">
          <thead>
            <tr>
              <th class="text-center">Ponta</th>
              <th class="text-center">Leitura</th>
              <th class="text-center">Releitura</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(reading, idx) in flowmeterWork.readings" :key="idx">
              <td class="text-center">
                <b>P{{ reading.ponta }}</b>
              </td>
              <td class="text-center">
                {{ reading.leitura.toFixed(2).replace(".", ",") }}
                <v-icon
                  v-if="
                    lower5Percent(
                      reading.leitura,
                      flowmeterWork.tips_ref_flow_rate
                    )
                  "
                  icon="mdi-arrow-down-box"
                  color="warning"
                ></v-icon>
                <v-icon
                  v-else-if="
                    higher5Percent(
                      reading.leitura,
                      flowmeterWork.tips_ref_flow_rate
                    )
                  "
                  icon="mdi-arrow-up-box"
                  color="error"
                ></v-icon>
                <v-icon
                  v-else
                  icon="mdi-checkbox-marked"
                  color="success"
                ></v-icon>
              </td>
              <td class="text-center">
                <div v-if="reading.releitura >= 0">
                  {{ reading.releitura.toFixed(2).replace(".", ",") }}
                  <v-icon
                    v-if="
                      lower5Percent(
                        reading.releitura,
                        flowmeterWork.tips_ref_flow_rate
                      )
                    "
                    icon="mdi-arrow-down-box"
                    color="warning"
                  ></v-icon>
                  <v-icon
                    v-else-if="
                      higher5Percent(
                        reading.releitura,
                        flowmeterWork.tips_ref_flow_rate
                      )
                    "
                    icon="mdi-arrow-up-box"
                    color="error"
                  ></v-icon>
                  <v-icon
                    v-else
                    icon="mdi-checkbox-marked"
                    color="success"
                  ></v-icon>
                </div>
                <span v-else>--</span>
              </td>
              <td class="text-center">
                {{ flowmeterWork.unit }}
              </td>
            </tr>
          </tbody>
        </v-table>
        <br />
        <p>
          Vazão de Referência:
          {{ flowmeterWork.tips_ref_flow_rate.toFixed(2).replace(".", ",") }}
          {{ flowmeterWork.unit }}
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useSnackbar } from "@/store";
import { differenceInHoursMinutes, formatDecimal } from "@/utils";
import axios from "axios";
import dayjs from "dayjs";
import SimpleMaskMoney from "simple-mask-money";
import tippy from "tippy.js";
import { computed, nextTick, onBeforeMount, reactive, ref } from "vue";
import EditActivityForm from "./EditActivityForm.vue";
import DeleteDialog from "@/components/DeleteDialog.vue";
import FormDialog from "@/components/FormDialog.vue";
import { required } from "@/validators";
import { useUsersStore } from "../users/store";

const page = ref(1);
const items = ref<any[]>([]);
const search = ref<string[]>([]);
const loading = ref(false);
const searchQuery = computed(() => {
  return search.value.join(",");
});
const selectedItem = ref();

const { showSnackbar } = useSnackbar();
const userStore = useUsersStore();

onBeforeMount(() => {
  listItems();
});

function listItems() {
  loading.value = true;
  axios
    .get("/activities")
    .then((res) => {
      items.value = res.data.sort((a: any, b: any) => {
        if (a.finish_date && !b.finish_date) {
          return 1;
        } else if (!a.finish_date && b.finish_date) {
          return -1;
        } else if (a.finish_date && b.finish_date) {
          return dayjs(b.finish_date).diff(dayjs(a.finish_date));
        }
        const now = dayjs();
        const aNext = nextExecution(a);
        const bNext = nextExecution(b);
        return aNext.diff(now, "day") - bNext.diff(now, "day");
      });
    })
    .finally(() => {
      loading.value = false;
      tippy("[data-tippy-content]", { arrow: false });
    });
}

function selectItem(item: any) {
  selectedItem.value = selectedItem.value == item ? undefined : item;
  nextTick(() => tippy("[data-tippy-content]", { arrow: false }));
}

function iterDeep(o: any) {
  let acc = "";
  Object.keys(o).forEach(function (k) {
    if (o[k] !== null && typeof o[k] === "object") {
      acc += iterDeep(o[k]);
    }
    if (typeof o[k] === "number") {
      acc += o[k].toString();
    }
    acc += o[k];
  });
  return acc;
}

function customFilter(
  value: string | number,
  query: string,
  item?: any
): boolean | number | [number, number] | [number, number][] {
  if (typeof value === "number") value = value.toString();
  if (typeof value === "object" && value !== null) value = iterDeep(value);

  const queries = query
    .trim()
    .split(",")
    .filter((q) => q)
    .map((q) => q.trim().toLocaleLowerCase());

  return (
    value != null &&
    query != null &&
    typeof value === "string" &&
    queries.every((q) =>
      value
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(q)
    )
  );
}

function nextExecution(activity: any) {
  if (activity.application && activity.application.executions.length > 0) {
    return dayjs(
      activity.application.executions[
        activity.application.executions.length - 1
      ].started_at
    ).add(activity.application.cycle_interval_days, "day");
  }
  return dayjs(activity.start_date);
}

function classifyActivity(activity: any) {
  const next = nextExecution(activity);
  if (next.isBefore(dayjs(), "day") && !activity.finish_date) {
    return "#E53935";
  }
  if (next.isSame(dayjs(), "day") && !activity.finish_date) {
    return "#43A047";
  }
  return "transparent";
}

function futureExecutions(activity: any) {
  const dates = [];
  const nExecutions = activity.application.executions.length;
  const n = activity.application.total_cycles - nExecutions;
  let date =
    nExecutions > 0
      ? dayjs(activity.application.executions.at(-1).started_at)
      : dayjs(activity.start_date).subtract(
          activity.application.cycle_interval_days,
          "day"
        );
  for (let i = 0; i < n; i++) {
    date = date.add(activity.application.cycle_interval_days, "day");
    dates.push(date);
  }
  return dates;
}

const openEditForm = ref(false);
const editActivity = ref();
function openEdit(activity: any) {
  editActivity.value = activity;
  openEditForm.value = true;
}

const deleteDialog = ref(false);
const deleteApplication = ref();

function openDeleteDialog(activity: any) {
  deleteDialog.value = true;
  deleteApplication.value = activity;
}

async function deleteActivity() {
  try {
    await axios.delete(`/application-activities/${deleteApplication.value.id}`);
    listItems();
    showSnackbar("Atividade excluida com sucesso", "success");
  } catch (err) {
    if (axios.isAxiosError(err)) {
      showSnackbar(err.response?.data.detail, "error");
      return;
    }
    showSnackbar("Erro ao excluir atividade", "error");
  } finally {
    deleteDialog.value = false;
  }
}

const finishFormDefaults = {
  observation: "",
};
const openFinishForm = ref(false);
const finishFormData = reactive({ ...finishFormDefaults });
const finishId = ref();
const formError = ref("");

function initFinish(activity: any) {
  finishId.value = activity.id;
  Object.assign(finishFormData, finishFormDefaults);
  openFinishForm.value = true;
}

async function submitFinish() {
  try {
    await axios.post(`/application-activities/${finishId.value}/finish`, {
      finish_date: dayjs().startOf("day").utc(true),
      observation: finishFormData.observation,
    });
    listItems();
    showSnackbar("Atividade finalizada com sucesso", "success");
    closeFinish();
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 422) {
        showSnackbar("Obrigatório informar justificativa", "error");
      }
    }
    showSnackbar("Erro ao finalizar atividade", "error");
    console.log(err);
  }
}

function closeFinish() {
  Object.assign(finishFormData, finishFormDefaults);
  openFinishForm.value = false;
}

const flowmeterDialog = ref(false);
const flowmeterWork = ref<any>();

function showFlowmeterWork(execution: any) {
  flowmeterWork.value = execution.flowmeter_work;
  flowmeterDialog.value = true;
}

function higher5Percent(reading: number, reference: number): boolean {
  return reading > reference + reference * 0.05;
}

function lower5Percent(reading: number, reference: number): boolean {
  return reading < reference - reference * 0.05;
}
</script>

<style scoped lang="scss">
.activity-item {
  cursor: pointer;
}

.activity-item.selected-item {
  background-color: #e8eaf6;
}
</style>
